import { EditTwoTone } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface EditIconProps {
    title?: string;
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const EditIcon = (props: EditIconProps) => {
    let icon = <EditTwoTone />;

    if (props.title) {
        icon = (
            <Tooltip title={props.title} arrow>
                <EditTwoTone fontSize={props.fontSize ?? 'medium'} />
            </Tooltip>
        );
    }

    return icon;
};

export default EditIcon;

import { Form } from 'react-final-form';
import FormTitle from '../../forms/FormTitle';
import Alerts, { AlertObj } from '../../Alerts/Alerts';
import { Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import FormRowContainer from '../../forms/FormRowContainer';
import FormButton from '../../forms/FormButton';
import { useCSVReader } from 'react-papaparse';
import CSVReaderUploadButton from '../../forms/CSVReaderUploadButton';
import { ParseResult } from 'papaparse';
import ImportContactsCSVHelper, {
    PeopleImportHelperValidateResponse,
    PeopleImportHelperUploadResponse
} from './ImportContactsCSVHelper';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import { StoreContext } from '../../../contexts/store';
import Logger from '../../../utils/logger';
import Overlay from '../../core/Overlay/Overlay';
import CustomDialog from '../CustomDialog/CustomDialog';
import { PersonModel } from '../../../models/Person';
import ConfirmDialog from '../CustomDialog/ConfirmDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { SourceMetadata } from '../../../models/types';

export enum ImportSource {
    Campaigns = 'campaigns',
    Contacts = 'contacts'
}

interface ImportContactsCSVFormProps {
    open: boolean;
    duplicatePeopleText?: string;
    importSource?: ImportSource;
    setOpen: (open: boolean) => void;
    onItemAddedHandler?:
        | ((
              peopleAdded: PersonModel[],
              peopleDuplicated: PersonModel[]
          ) => void)
        | (() => void);
}

const ImportContactsCSVForm = (
    props: ImportContactsCSVFormProps
): JSX.Element => {
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const { CSVReader } = useCSVReader();
    const [rowsToImport, setRowsToImport] = useState<string[][]>();
    const [rowsMeta, setRowsMeta] =
        useState<PeopleImportHelperValidateResponse>();
    const { session } = useContext<SessionContextType>(SessionContext);
    const { store } = useContext(StoreContext);
    const [processing, setProcessing] = useState<boolean>(false);
    const [
        peopleImportHelperUploadResponse,
        setPeopleImportHelperUploadResponse
    ] = useState<PeopleImportHelperUploadResponse>();
    const [openConfirmImportFinished, setOpenConfirmImportFinished] =
        useState<boolean>(false);
    const [importMeta, setImportMeta] = useState<SourceMetadata>();

    const clearComponent = () => {
        setRowsMeta(undefined);
        setRowsToImport(undefined);
        setProcessing(false);
    };

    const closeComponent = () => {
        clearComponent();
        setAlerts([]);
        props.setOpen(false);
    };

    const onSubmitHandler = async () => {
        try {
            setProcessing(true);

            if (store.currentClientId && rowsToImport) {
                const importResult = await ImportContactsCSVHelper.import(
                    session,
                    session.user?.clientId || store.currentClientId,
                    rowsToImport,
                    importMeta
                );

                const peopleImportResponse = importResult;
                setPeopleImportHelperUploadResponse(peopleImportResponse);
                setOpenConfirmImportFinished(true);

                if (props.onItemAddedHandler) {
                    props.onItemAddedHandler(
                        peopleImportResponse.peopleAddedModel,
                        peopleImportResponse.peopleDuplicatedModel
                    );
                }

                return;
            }
        } catch (err) {
            Logger.error(JSON.stringify(err));
        }

        setAlerts([
            ...alerts,
            {
                severity: 'warning',
                text: 'Something went wrong importing contacts. No client selected or no rows to import'
            }
        ]);
    };

    const onUploadAccepted = (
        results: ParseResult<string[]>,
        file?: File
    ): void => {
        if (results.data.length) {
            const rowsMeta = ImportContactsCSVHelper.validate(results.data);

            if (!rowsMeta.valid || !rowsMeta.headers) {
                console.log(rowsMeta);
                setAlerts([
                    ...alerts,
                    {
                        severity: 'warning',
                        text: 'Unprocessable CSV file. Please download our sample.csv for a valid format.'
                    }
                ]);
                return;
            }

            setRowsToImport(results.data);
            setRowsMeta(rowsMeta);

            if (file && file.name) {
                setImportMeta({ filename: file.name });
            }
        } else {
            setRowsToImport(undefined);
        }

        if (results.errors.length) {
            setAlerts([
                ...alerts,
                {
                    severity: 'warning',
                    text: JSON.stringify(results.errors[0])
                }
            ]);
        }
    };

    const onUploadRejected = (results: ParseResult<string[]>): void => {
        if (results.errors.length) {
            setAlerts([
                ...alerts,
                {
                    severity: 'warning',
                    text: JSON.stringify(results.errors[0])
                }
            ]);
        }
    };

    const onImportFinished = () => {
        closeComponent();
        clearComponent();
    };

    const getExistingMessage = () => {
        const count =
            peopleImportHelperUploadResponse?.peopleExists &&
            Object.keys(peopleImportHelperUploadResponse?.peopleExists).length;
        if (count === 0) {
            return '';
        }

        const verb = `${count && count > 1 ? 'were' : 'was'}`;
        const singularOrPlural = `${count && count > 1 ? 's' : ''}`;

        if (props.importSource === ImportSource.Contacts) {
            return `${count} existing contact${singularOrPlural} ${verb} found in your contact list.`;
        }
        return `${count} existing contact${singularOrPlural} ${verb} found in your contact list and added to this campaign.`;
    };

    const getDuplicatedMessage = () => {
        const count =
            peopleImportHelperUploadResponse?.peopleDuplicated &&
            Object.keys(peopleImportHelperUploadResponse?.peopleDuplicated)
                .length;
        if (count === 0) {
            return '';
        }

        const verb = `${count && count > 1 ? 'were' : 'was'}`;
        const singularOrPlural = `${count && count > 1 ? 's' : ''}`;

        if (props.importSource === ImportSource.Contacts) {
            return `${count} contact${singularOrPlural} ${verb} duplicate${singularOrPlural}.`;
        }

        const msg = `${count} contact${singularOrPlural} ${verb} duplicate${singularOrPlural} of contact${singularOrPlural} already in this campaign and not added. `;
        return (
            <Typography sx={{ mb: '1rem' }}>
                {msg}
                <Link
                    href={generateLinkWithDuplicatesToDownload()}
                    target='_blank'
                    rel='noreferrer'
                    download='duplicates.txt'
                >
                    Click here
                </Link>{' '}
                to download duplicates.
            </Typography>
        );
    };

    const generateLinkWithDuplicatesToDownload = () => {
        const count =
            peopleImportHelperUploadResponse?.peopleDuplicated &&
            Object.keys(peopleImportHelperUploadResponse?.peopleDuplicated)
                .length;
        if (count === 0) {
            return '';
        }
        let text: string = 'First Name,Last Name,Email,Company\n';
        peopleImportHelperUploadResponse?.peopleDuplicatedModel.forEach(
            (value) => {
                text += `${value.name},${value.lastName ?? ''},${value.email},${
                    value.companyName ?? ''
                }\n`;
            }
        );
        return window.URL.createObjectURL(
            new Blob([text], { type: 'text/plain' })
        );
    };

    return (
        <CustomDialog open={props.open} onClose={closeComponent}>
            <Overlay processing={processing} />
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Form
                onSubmit={onSubmitHandler}
                render={({ handleSubmit, form }) => (
                    <form {...form} onSubmit={handleSubmit}>
                        <FormTitle text='Import Contacts (CSV)' />
                        <Typography sx={{ mb: '1rem' }}>
                            Upload your contacts by attaching a CSV file.{' '}
                            <Link href='/assets/sample.csv' target='_blank'>
                                Download our template file
                            </Link>{' '}
                            to see how to organize contact data in your list.
                        </Typography>
                        <FormRowContainer>
                            <CSVReader
                                onUploadAccepted={onUploadAccepted}
                                onUploadRejected={onUploadRejected}
                            >
                                {CSVReaderUploadButton}
                            </CSVReader>
                        </FormRowContainer>
                        {rowsMeta && rowsMeta.valid && (
                            <FormRowContainer>
                                <Stack direction='column'>
                                    {rowsToImport && rowsToImport.length && (
                                        <Typography>
                                            People to import:{' '}
                                            {rowsToImport.length -
                                                (rowsMeta.headers ? 1 : 0)}
                                        </Typography>
                                    )}
                                    {/* <Typography>
                                        Headers:{' '}
                                        {rowsMeta.headers ? 'Yes' : 'No'}
                                    </Typography>
                                    <Typography>
                                        Name column:{' '}
                                        {Number(rowsMeta.namePos) + 1}
                                    </Typography>
                                    <Typography>
                                        Email column:{' '}
                                        {Number(rowsMeta.emailPos) + 1}
                                    </Typography>
                                    <Typography>
                                        Company column:{' '}
                                        {rowsMeta.companyPos !== undefined
                                            ? Number(rowsMeta.companyPos) + 1
                                            : 'N/A'}
                                    </Typography> */}
                                </Stack>
                            </FormRowContainer>
                        )}
                        <Divider sx={{ my: '1rem' }} />
                        <FormRowContainer>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    <FormButton
                                        disabled={
                                            !rowsToImport ||
                                            rowsToImport.length <= 0
                                        }
                                        type='submit'
                                    >
                                        Process Import
                                    </FormButton>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Link
                                        href='/assets/sample.csv'
                                        target='_blank'
                                    >
                                        sample.csv
                                    </Link>
                                </Grid>
                            </Grid>
                        </FormRowContainer>
                    </form>
                )}
            />
            <ConfirmDialog
                open={openConfirmImportFinished}
                setOpen={setOpenConfirmImportFinished}
                title='Import Finished'
                confirmOptionText='Ok'
                cancelOptionText=''
                onConfirmAction={onImportFinished}
            >
                <DialogContent>
                    <DialogContentText>
                        <h3>Success!</h3>
                        {`${
                            peopleImportHelperUploadResponse?.peopleAddedModel
                                .length
                        } new contact${
                            peopleImportHelperUploadResponse?.peopleAddedModel &&
                            peopleImportHelperUploadResponse?.peopleAddedModel
                                .length > 1
                                ? 's'
                                : ''
                        } added.`}
                        <br />
                        {getExistingMessage()}
                        <br />
                    </DialogContentText>
                    {getDuplicatedMessage()}
                </DialogContent>
            </ConfirmDialog>
        </CustomDialog>
    );
};

export default ImportContactsCSVForm;

import * as yup from 'yup';
import CompanyAPI from '../../../api/CompanyAPI';
import { SessionType } from '../../../contexts/session';
import PersonAPI from '../../../api/PersonAPI';
import { NewPerson, PersonModel } from '../../../models/Person';
import Logger from '../../../utils/logger';
import { SourceMetadata } from '../../../models/types';
import { ObjectType } from '@advocate-insights/ms-common';

export interface PeopleImportHelperValidateResponse {
    valid: boolean;
    headers?: boolean;
    namePos?: number;
    lastNamePos?: number;
    emailPos?: number;
    companyPos?: number;
}

export interface PeopleImportHelperUploadResponse {
    peopleAddedModel: PersonModel[];
    peopleDuplicatedModel: PersonModel[];
    peopleExists: ObjectType;
    peopleDuplicated: ObjectType;
}

const personNameOptions = [
    'name',
    'firstname',
    'first name',
    'contact',
    'person'
];
const personLastNameOptions = ['lastname', 'last name', 'surname'];
const personEmailOptions = ['email'];
const personCompanyOptions = ['company'];

const CSV_SOURCE = 'csv';

class ImportContactsCSVHelper {
    public static import = async (
        session: SessionType,
        clientId: string,
        rows: string[][],
        metadata?: SourceMetadata
    ): Promise<PeopleImportHelperUploadResponse> => {
        const rowsMeta = this.validate(rows);
        const peopleAddedModel: PersonModel[] = [];
        const peopleDuplicatedModel: PersonModel[] = [];
        const peopleExists: ObjectType = {};
        const peopleDuplicated: ObjectType = {};

        if (!rowsMeta.valid) {
            throw new Error(
                'Import failed: CSV validation error' + JSON.stringify(rowsMeta)
            );
        }

        const addedCompanies: { [key: string]: string } = {};

        // Detect duplicates and add companies
        await Promise.all(
            (rowsMeta.headers ? rows.slice(1) : rows).map(async (row) => {
                if (!this.validateRow(rowsMeta, row)) {
                    return;
                }

                // This try..catch is to keep going even after an exception
                try {
                    // Detect duplicate first
                    if (
                        rowsMeta.emailPos !== undefined &&
                        row[rowsMeta.emailPos]
                    ) {
                        const people = await PersonAPI.getMany(session, {
                            clientId,
                            email: String(row[rowsMeta.emailPos])
                        });

                        if (people.data.length) {
                            people.data.map((value) => {
                                if (peopleExists[value.email]) {
                                    peopleDuplicated[value.email] =
                                        peopleDuplicated[value.email] || 0 + 1;
                                } else {
                                    peopleExists[value.email] = 1;
                                }
                                peopleDuplicatedModel.push({
                                    id: value.id,
                                    name: value.name,
                                    email: value.email,
                                    clientId: value.clientId
                                });
                            });
                        }
                    }

                    // Process company (if available) first
                    // let companyId: string | undefined = undefined;

                    if (
                        rowsMeta.companyPos !== undefined &&
                        row[rowsMeta.companyPos]
                    ) {
                        const companies = await CompanyAPI.getMany(session, {
                            clientId,
                            name: String(row[rowsMeta.companyPos])
                        });

                        if (companies.data.length) {
                            if (
                                !addedCompanies[
                                    String(row[rowsMeta.companyPos])
                                ]
                            ) {
                                addedCompanies[
                                    String(row[rowsMeta.companyPos])
                                ] = String(companies.data[0]?.id);
                            }
                        } else {
                            const newCompany = await CompanyAPI.create(
                                session,
                                {
                                    clientId,
                                    name: String(row[rowsMeta.companyPos])
                                }
                            );

                            if (
                                newCompany.id &&
                                !addedCompanies[
                                    String(row[rowsMeta.companyPos])
                                ]
                            ) {
                                addedCompanies[
                                    String(row[rowsMeta.companyPos])
                                ] = newCompany.id;
                            }
                        }
                    }
                } catch (err) {
                    await Logger.error(JSON.stringify(err));
                }
            })
        );

        // Add People
        const rowsRetry: string[][] = [];

        await Promise.all(
            (rowsMeta.headers ? rows.slice(1) : rows).map(async (row) => {
                if (!this.validateRow(rowsMeta, row)) {
                    return;
                }

                const personAdded = await this.addPerson(
                    session,
                    row,
                    rowsMeta,
                    addedCompanies,
                    rowsRetry,
                    clientId,
                    metadata
                );

                if (personAdded?.id) {
                    peopleAddedModel.push(personAdded);
                }
            })
        );

        /**
         * This is necessary because sometimes the new added companies do not
         * propagate to the people service fast enough, and adding a person
         * fails because the company ID is not found within the companies in
         * the service. A better approach would be to batch adding companies,
         * and batch adding people with multiple retries incorporated in the
         * logic.
         */
        if (rowsRetry.length) {
            await Promise.all(
                rowsRetry.map(async (row) => {
                    if (!this.validateRow(rowsMeta, row)) {
                        return;
                    }

                    const personAdded = await this.addPerson(
                        session,
                        row,
                        rowsMeta,
                        addedCompanies,
                        rowsRetry,
                        clientId,
                        metadata
                    );

                    if (personAdded?.id) {
                        peopleAddedModel.push(personAdded);
                    }
                })
            );
        }

        return {
            peopleAddedModel,
            peopleDuplicatedModel,
            peopleExists,
            peopleDuplicated
        };
    };

    private static validateRow = (
        rowsMeta: PeopleImportHelperValidateResponse,
        row: string[]
    ): boolean => {
        if (
            rowsMeta.emailPos !== undefined &&
            row[rowsMeta.emailPos]?.trim() === 'johnsmith@test.com' &&
            rowsMeta.namePos !== undefined &&
            row[rowsMeta.namePos]?.trim() === 'John'
        ) {
            return false;
        }

        console.log(rowsMeta, row);
        return true;
    };

    private static addPerson = async (
        session: SessionType,
        row: string[],
        rowsMeta: PeopleImportHelperValidateResponse,
        addedCompanies: { [key: string]: string },
        rowsRetry: string[][],
        clientId: string,
        metadata?: SourceMetadata
    ): Promise<PersonModel | undefined> => {
        // This try..catch is to keep going even after an exception
        try {
            // Detect duplicate first
            if (rowsMeta.emailPos !== undefined && row[rowsMeta.emailPos]) {
                const people = await PersonAPI.getMany(session, {
                    clientId,
                    email: String(row[rowsMeta.emailPos])
                });

                if (people.data.length) {
                    return;
                }
            }

            // Save person
            if (
                rowsMeta.emailPos !== undefined &&
                rowsMeta.namePos !== undefined &&
                row[rowsMeta.emailPos] &&
                row[rowsMeta.namePos]
            ) {
                const newPerson: NewPerson = {
                    clientId,
                    email: String(row[rowsMeta.emailPos]),
                    name: String(row[rowsMeta.namePos]),
                    source: CSV_SOURCE
                };

                if (
                    rowsMeta.lastNamePos !== undefined &&
                    row[rowsMeta.lastNamePos] &&
                    row[rowsMeta.lastNamePos]!.length > 0
                ) {
                    newPerson.lastName = String(row[rowsMeta.lastNamePos]);
                }

                if (
                    rowsMeta.companyPos !== undefined &&
                    row[rowsMeta.companyPos] &&
                    addedCompanies[String(row[rowsMeta.companyPos])]
                ) {
                    newPerson.companyId = String(
                        addedCompanies[String(row[rowsMeta.companyPos])]
                    );
                }

                if (metadata) {
                    newPerson.sourceMetadata = metadata;
                }

                const response = await PersonAPI.create(
                    session,
                    newPerson,
                    true
                );

                if (
                    !response.id &&
                    response.companyId &&
                    response.companyId.substring(0, 9) === 'Could not'
                ) {
                    rowsRetry.push(row);
                }

                if (response.id) {
                    return response as PersonModel;
                }
            }
        } catch (err) {
            if (err && typeof err) {
                await Logger.error(JSON.stringify(err));
            }
        }

        return;
    };

    public static validate = (
        rows: string[][]
    ): PeopleImportHelperValidateResponse => {
        const response: PeopleImportHelperValidateResponse = {
            valid: false
        };

        // return if we don't have processable rows
        if (!rows[0] || !this.processableRows(rows)) {
            return response;
        }

        // Do we have headers?
        response.headers = this.hasHeaders(rows[0]);

        // Email position?
        const emailPos = this.emailPosition(rows, response.headers, []);
        if (emailPos === undefined) {
            return response;
        }
        response.emailPos = emailPos;

        // Person name position?
        const namePos = this.namePosition(rows, response.headers, [emailPos]);
        if (namePos === undefined) {
            return response;
        }
        response.namePos = namePos;

        // Person lastName position?
        const lastNamePos = this.lastNamePosition(rows, response.headers, [
            emailPos,
            namePos
        ]);
        if (lastNamePos !== undefined) {
            response.lastNamePos = lastNamePos;
        }
        let companyPos;
        try {
            //  Person company position?
            companyPos = this.companyPosition(rows, response.headers, [
                emailPos,
                namePos,
                lastNamePos!
            ]);
        } catch (err: unknown) {
            Logger.error(JSON.stringify(err));
        }

        if (companyPos !== undefined) {
            response.companyPos = companyPos;
        }
        response.valid = true;

        return response;
    };

    private static companyPosition = (
        rows: string[][],
        headers: boolean,
        skipPos: number[]
    ): number | undefined => {
        return this.propertyPosition(
            rows,
            headers,
            personCompanyOptions,
            skipPos,
            yup.string().min(2)
        );
    };

    private static emailPosition = (
        rows: string[][],
        headers: boolean,
        skipPos: number[]
    ): number | undefined => {
        return this.propertyPosition(
            rows,
            headers,
            personEmailOptions,
            skipPos,
            yup.string().email().required()
        );
    };

    private static namePosition = (
        rows: string[][],
        headers: boolean,
        skipPos: number[]
    ): number | undefined => {
        return this.propertyPosition(
            rows,
            headers,
            personNameOptions,
            skipPos,
            yup.string().min(2).required()
        );
    };

    private static lastNamePosition = (
        rows: string[][],
        headers: boolean,
        skipPos: number[]
    ): number | undefined => {
        return this.propertyPosition(
            rows,
            headers,
            personLastNameOptions,
            skipPos,
            yup.string().min(2)
        );
    };

    private static propertyPosition = (
        rows: string[][],
        headers: boolean,
        propertyNames: string[],
        skipPos: number[],
        validation?: yup.AnyObject
    ): number | undefined => {
        let position: number | undefined;
        let error: boolean | undefined;

        if (!rows[0] || (headers && !rows[1])) {
            return;
        }

        // If we have headers we attempt to parse using them
        if (rows[0] && headers) {
            rows[0].forEach((value, idx) => {
                if (
                    !skipPos.includes(idx) &&
                    propertyNames.includes(value.toLowerCase().trim())
                ) {
                    if (position === undefined) {
                        position = idx;
                    } else {
                        error = true;
                    }
                }
            });
        }

        // Attempt to define position by first value matching criteria
        if (position === undefined) {
            (headers && rows[1] ? rows[1] : rows[0]).forEach((value, idx) => {
                if (
                    !skipPos.includes(idx) &&
                    (validation === undefined ||
                        validation.isValidSync(value) === true)
                ) {
                    if (position === undefined) {
                        position = idx;
                    } else {
                        error = true;
                    }
                }
            });
        }

        if (!error && position !== undefined) {
            rows.forEach((row, idx) => {
                if (error || (idx === 0 && headers)) {
                    return;
                }

                if (
                    !row[Number(position)] ||
                    (validation !== undefined &&
                        validation.isValidSync(row[Number(position)]) !== true)
                ) {
                    error = true;
                }
            });
        }

        if (error) {
            return;
        }
        return position;
    };

    private static hasHeaders = (firstRow: string[]): boolean => {
        // If we can't find a @ we assume we have headers
        for (const value of firstRow) {
            if (value && yup.string().email().required().isValidSync(value)) {
                return false;
            }
        }

        return true;
    };

    private static processableRows = (rows: string[][]): boolean => {
        // Examine 2nd row for a processable row
        if (rows[1]) {
            for (const value of rows[1]) {
                if (value && value.indexOf('@')) {
                    return true;
                }
            }
        }

        // Maybe it's a 1 row CSV without headers?
        if (rows[0]) {
            for (const value of rows[0]) {
                if (value && value.indexOf('@')) {
                    return true;
                }
            }
        }

        return false;
    };
}

export default ImportContactsCSVHelper;

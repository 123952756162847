import { PaginatedCollection } from '@advocate-insights/ms-common';
import CampaignAPI, { SelectRule } from '../../api/CampaignAPI';
import { APIGetPaginatedParams } from '../../api/types';
import { PageSize } from '../../components/common/List/List';
import { SessionType } from '../../contexts/session';
import { SelectMode } from './types';
import { PersonInCampaignRecord } from '../../models/Campaign';

class PeopleInCampaignController {
    public static smartSelect = async (
        session: SessionType,
        sourceCampaignId: string,
        targetCampaignId: string,
        mode: SelectMode,
        rules?: SelectRule[]
    ): Promise<void> => {
        if (mode === SelectMode.Clone) {
            const response = await CampaignAPI.clone(
                session,
                targetCampaignId,
                sourceCampaignId
            );

            if (response && response.length) {
                throw new Error(
                    'Clone operation incomplete. Some people have not been assigned'
                );
            }
        } else if (mode === SelectMode.FromResults && rules) {
            const response = await CampaignAPI.smartSelect(
                session,
                targetCampaignId,
                sourceCampaignId,
                rules
            );

            if (response && response.length) {
                throw new Error(
                    'Smart selection operation incomplete. Some people have not been assigned'
                );
            }
        }
    };

    public static getPeopleInCampaign = async (
        session: SessionType,
        campaignId: string,
        lastKey?: string | { [key: string]: unknown },
        pageSize = PageSize
    ): Promise<PaginatedCollection<PersonInCampaignRecord>> => {
        const APIParams: APIGetPaginatedParams = {
            scanLimit: pageSize.toString()
        };

        if (lastKey) {
            APIParams.lastKey = lastKey;
        }

        const peopleResponse = await CampaignAPI.getPeople(
            session,
            campaignId,
            APIParams
        );

        return peopleResponse;
    };
}

export default PeopleInCampaignController;

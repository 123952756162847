import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import {
    APIGetPaginatedParams,
    ApiError,
    ApiErrorType,
    ApiErrorObject
} from './types';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';
import { NewPerson, PersonModel } from '../models/Person';

interface PersonAPIGetPaginatedParams extends APIGetPaginatedParams {
    clientId?: string;
    email?: string;
}
export interface PersonRecordAPI extends PersonModel {
    companyName?: string;
}

class PersonAPI {
    private static getPersonAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_PERSON_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static create = async (
        session: SessionType,
        personData: NewPerson,
        importing?: boolean
    ): Promise<ApiErrorObject | PersonModel> => {
        const personAPI = PersonAPI.getPersonAPIAxiosObj(session);

        try {
            const person = await personAPI.post(
                importing ? '?import=1' : '',
                personData
            );

            if (person.data) {
                return person.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('creating person');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('creating person');
    };

    public static update = async (
        session: SessionType,
        personData: PersonRecordAPI
    ): Promise<ApiErrorObject | PersonModel> => {
        const personAPI = PersonAPI.getPersonAPIAxiosObj(session);

        try {
            const updatedPerson = await personAPI.put<PersonModel>(
                `/${personData.id}`,
                personData
            );

            if (updatedPerson.data) {
                return updatedPerson.data;
            }
        } catch (err: unknown) {
            console.log(err);
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('updating person');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }
        return generateApiUnexpectedError('updating person');
    };

    public static getMany = async (
        session: SessionType,
        params: PersonAPIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<PersonRecordAPI>> => {
        const personAPI = PersonAPI.getPersonAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params };

        if (params.lastKey) {
            filter.params.lastKey = JSON.stringify(params.lastKey);
        }

        const response = await personAPI.get<
            PaginatedCollection<PersonRecordAPI>
        >('', filter);

        if (response.status === 200 && response.data) {
            return response.data;
        } else {
            throw new Error('Error retrieving people');
        }
    };

    public static getAll = async (
        session: SessionType,
        params: PersonAPIGetPaginatedParams = {}
    ): Promise<PersonModel[]> => {
        let persons: PersonModel[] = [];
        const processNext = true;
        let iterationLimit = 20;
        const pageParams: PersonAPIGetPaginatedParams = params;

        while (processNext && iterationLimit) {
            const personPage = await this.getMany(session, pageParams);

            if (personPage.count > 0) {
                persons = [...persons, ...personPage.data];
            }

            if (!personPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = personPage.lastKey;
        }

        if (iterationLimit <= 0) {
            throw new ApiError(
                'PersonAPI.getAll() reached its iteration limit',
                ApiErrorType.IterationLimit
            );
        }

        return persons;
    };
}

export default PersonAPI;

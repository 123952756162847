import { createContext } from 'react';
import { EmailTemplateRecord } from '../models/EmailTemplate';

export interface EmailTemplatesContextType {
    emailTemplates: EmailTemplateRecord[];
    setEmailTemplates: (emailTemplates: EmailTemplateRecord[]) => void;
}

export const EmailTemplatesContext = createContext<EmailTemplatesContextType>({
    emailTemplates: [],
    setEmailTemplates: () => {
        return;
    }
});

import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

const ListTitle = (props: PropsWithChildren): JSX.Element => {
    return (
        <Typography
            sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold'
            }}
        >
            {props.children}
        </Typography>
    );
};

export default ListTitle;

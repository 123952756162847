import { useContext, useEffect, useState } from 'react';
import CustomDialog from '../../../components/common/CustomDialog/CustomDialog';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import { Box, Divider, Stack } from '@mui/material';
import SurveyResponseAPI from '../../../api/SurveyResponseAPI';
import Logger from '../../../utils/logger';
import { SessionContext } from '../../../contexts/session';
import { ObjectType } from '@advocate-insights/ms-common';
import Overlay from '../../../components/core/Overlay/Overlay';
import ListTitle from '../../../components/common/List/ListTitle';

interface SurveyResponseViewProps {
    submissionId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    // response: ObjectType;
}

const SurveyResponseView = (props: SurveyResponseViewProps): JSX.Element => {
    const { session } = useContext(SessionContext);
    const [formattedResponse, setFormattedResponse] = useState<JSX.Element>(
        <></>
    );
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [processing, setProcessing] = useState<boolean>(false);

    const getFormattedResponse = (response?: ObjectType): JSX.Element => {
        if (
            !response ||
            typeof response !== 'object' ||
            Object.keys(response).length <= 0
        ) {
            return <></>;
        }

        return (
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='160px'
            >
                <Stack direction='row' spacing={1}>
                    <Stack direction='column'>
                        {Object.keys(response).map((key) => (
                            <Box
                                textAlign='left'
                                sx={{
                                    mb: '.2rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                {key}:
                            </Box>
                        ))}
                    </Stack>
                    <Stack direction='column'>
                        {Object.keys(response).map((key) => (
                            <Box sx={{ mb: '.2rem' }}>
                                {String(response[key])}
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            </Box>
        );
    };

    useEffect(() => {
        const getResponse = async (): Promise<void> => {
            setProcessing(true);

            try {
                const apiResponse = await SurveyResponseAPI.get(
                    session,
                    props.submissionId
                );

                if (apiResponse && apiResponse.response) {
                    setFormattedResponse(
                        getFormattedResponse(apiResponse.response)
                    );
                }
            } catch (err: unknown) {
                const errNo = await Logger.error(
                    `Error getting survey response: ${JSON.stringify(err)}`
                );
                setAlerts([
                    ...alerts,
                    {
                        severity: 'error',
                        text: `[${errNo}]: Error retrieving response`
                    }
                ]);
            } finally {
                setProcessing(false);
            }
        };

        if (props.submissionId) {
            getResponse();
        }
    }, [props.submissionId, session.idToken]);

    const onCloseHandler = (): void => {
        props.setOpen(false);
    };

    return (
        <CustomDialog open={props.open} onClose={onCloseHandler}>
            <Overlay processing={processing} />
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <ListTitle>Response</ListTitle>
            <Divider sx={{ mb: '1rem' }} />
            {formattedResponse}
        </CustomDialog>
    );
};

export default SurveyResponseView;

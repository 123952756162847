class ArrayHelper {
    public static equals = <T = string>(a: T[], b: T[]): boolean => {
        if (a.length !== b.length) {
            return false;
        }

        const elements: T[] = [...a, ...b];

        for (const x of elements) {
            const count1 = a.filter((e) => e === x).length;
            const count2 = b.filter((e) => e === x).length;

            if (count1 !== count2) {
                return false;
            }
        }

        return true;
    };
}

export default ArrayHelper;

import { ObjectType } from '@advocate-insights/ms-common';
import { Typography } from '@mui/material';
import Handlebars from 'handlebars';
import parse from 'html-react-parser';

interface RenderedTemplateProps {
    template: string;
    vars?: ObjectType;
}

const RenderedTemplate = (props: RenderedTemplateProps): JSX.Element => {
    const vars = props.vars ?? {
        first_name: 'John',
        last_name: 'Doe',
        full_name: 'John Doe',
        submissionId: 'abc123',
        domain: process.env.REACT_APP_SURVEY_URL,
        survey_link: `${process.env.REACT_APP_SURVEY_URL}/survey?id=abc123`,
        g2_shortform_link: `${
            process.env.REACT_APP_SURVEY_URL
        }/g2review?id=abc123&email=${encodeURIComponent('user@email.com')}`
    };

    let content: string | JSX.Element | JSX.Element[] = '';

    try {
        content = parse(Handlebars.compile(props.template)(vars));
    } catch (err: unknown) {
        /* Avoid exception */
        content = (
            <Typography
                color='error'
                sx={{
                    fontWeight: 'bold',
                    fontSize: 13,
                    mt: 2
                }}
            >
                Template with errors!
            </Typography>
        );
    }

    return <>{content}</>;
};

export default RenderedTemplate;

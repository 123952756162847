import { Button, Grid } from '@mui/material';
import AddIcon from '../../../components/Icons/AddIcon';
import { useCallback, useContext, useMemo, useState } from 'react';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import AuthHelper from '../../../utils/AuthHelper/AuthHelper';
import { permissions } from '../../../utils/AuthHelper/types';
import { StoreContext } from '../../../contexts/store';
import {
    CampaignModel,
    PersonInCampaignRecord
} from '../../../models/Campaign';
import usePermissions from '../../../hooks/usePermissions';
import PersonForm from '../../people/components/PersonForm';
import { PersonRecordAPI } from '../../../api/PersonAPI';
import CampaignAPI from '../../../api/CampaignAPI';
import { ApiErrorObject } from '../../../api/types';
import Logger from '../../../utils/logger';
import { PersonModel } from '../../../models/Person';
import FactCheckIcon from '../../../components/Icons/FactCheckIcon';
import SelectPeopleInCampaign from './SelectPeopleInCampaign';
import ImportContactsCSVButton from '../../../components/common/ImportContactsCSV/ImportContactsCSVButton';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import ImportContactsCRMHelper from '../../../components/common/ImportContactsCRM/ImportContactsCRMHelper';
import ImportContactsCRMButton from '../../../components/common/ImportContactsCRM/ImportContactsCRMButton';
import { ImportSource } from '../../../components/common/ImportContactsCSV/ImportContactsCSVForm';

interface PeopleInCampaignHeaderProps {
    campaign: CampaignModel | undefined;
    refreshPeopleInCampaign: () => void;
    peopleInCampaign: PersonInCampaignRecord[];
}

const PeopleInCampaignHeader = (
    props: PeopleInCampaignHeaderProps
): JSX.Element => {
    usePermissions(permissions.campaignsAny);

    const { session } = useContext<SessionContextType>(SessionContext);
    const { store } = useContext(StoreContext);
    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [selectOpen, setSelectOpen] = useState<boolean>(false);
    const renderButtons = useMemo(
        () =>
            Boolean(
                AuthHelper.authorize(session, permissions.peopleWrite) &&
                    store.currentClientId &&
                    props.campaign &&
                    props.campaign.status === 'new'
            ),
        [props.campaign, store.currentClientId]
    );

    const onItemAddedHandler = useCallback(
        async (person: PersonRecordAPI): Promise<void> => {
            if (props.campaign?.id && session.idToken) {
                const unprocessedPeople = await CampaignAPI.addPeople(
                    session,
                    props.campaign?.id,
                    [person.id]
                );

                if (!(unprocessedPeople instanceof Array)) {
                    await Logger.error(JSON.stringify(unprocessedPeople));
                }

                props.refreshPeopleInCampaign();
            }
        },
        [props.campaign, session.idToken]
    );

    const onManyItemsAddedHandler = useCallback(
        async (
            peopleAdded: PersonModel[],
            peopleDuplicated: PersonModel[]
        ): Promise<void> => {
            if (props.campaign?.id) {
                let unprocessedPeople: string[] | ApiErrorObject = [''];
                let retries = 3;

                const peopleToAdd: PersonModel[] = [
                    ...peopleAdded,
                    ...peopleDuplicated
                ];

                while (retries && unprocessedPeople.length) {
                    unprocessedPeople = await CampaignAPI.addPeople(
                        session,
                        props.campaign.id,
                        [...peopleToAdd.map((person) => person.id)]
                    );

                    if (
                        !(unprocessedPeople instanceof Array) ||
                        unprocessedPeople.length === 0
                    ) {
                        break;
                    }

                    retries--;
                }

                if (!(unprocessedPeople instanceof Array)) {
                    await Logger.error(JSON.stringify(unprocessedPeople));
                }

                props.refreshPeopleInCampaign();
            }
        },
        [props.campaign, session.idToken]
    );

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                sx={{ mb: 2 }}
            >
                <Grid item>
                    {renderButtons && (
                        <>
                            <IfFeatureEnabled feature='crm-integrations'>
                                {ImportContactsCRMHelper.hasCallableConnections(
                                    store.connections
                                ) && (
                                    <ImportContactsCRMButton
                                        onContactsAdded={
                                            onManyItemsAddedHandler
                                        }
                                    />
                                )}
                            </IfFeatureEnabled>
                            <ImportContactsCSVButton
                                importSource={ImportSource.Campaigns}
                                onContactsAddedHandler={onManyItemsAddedHandler}
                            />
                            <Button
                                onClick={() => setAddOpen(true)}
                                sx={{ ml: 1 }}
                                variant='contained'
                                startIcon={<AddIcon fontSize='small' />}
                            >
                                Contact
                            </Button>
                            <Button
                                onClick={() => setSelectOpen(true)}
                                sx={{ ml: 1 }}
                                variant='contained'
                                startIcon={<FactCheckIcon fontSize='small' />}
                            >
                                Select Contacts
                            </Button>
                            {/* <Button
                                onClick={onSmartSelectClickHandler}
                                sx={{ mt: { xs: 2, md: 0 }, ml: 1 }}
                                variant='contained'
                                startIcon={<AddIcon fontSize='small' />}
                            >
                                Smart Select
                            </Button> */}
                        </>
                    )}
                </Grid>
            </Grid>
            {props.campaign && (
                <>
                    {/* <SmartSelector
                        open={smartSelectOpen}
                        setOpen={setSmartSelectOpen}
                        campaignId={props.campaign.id}
                        refreshPeopleInCampaign={props.refreshPeopleInCampaign}
                    /> */}
                    <PersonForm
                        open={addOpen}
                        setOpen={setAddOpen}
                        onItemAddedHandler={onItemAddedHandler}
                    />
                    <SelectPeopleInCampaign
                        open={selectOpen}
                        setOpen={setSelectOpen}
                        campaign={props.campaign}
                        refreshPeopleInCampaign={props.refreshPeopleInCampaign}
                        peopleInCampaign={props.peopleInCampaign}
                    />
                </>
            )}
        </>
    );
};

export default PeopleInCampaignHeader;

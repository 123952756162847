import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { Field } from 'react-final-form';
import { DateOrTimeView } from '@mui/x-date-pickers';

interface FormDatePickerProps {
    name: string;
    label?: string;
    views?: DateOrTimeView[];
    ampm?: boolean;
    format?: string;
    minDate?: dayjs.Dayjs;
    minTime?: dayjs.Dayjs;
}

const DATE_FORMAT = 'MM/DD/YYYY HH:00:00';

const FormDatePicker = (
    props: React.PropsWithChildren<FormDatePickerProps>
): JSX.Element => {
    return (
        <Field name={props.name}>
            {({ input }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        {...input}
                        minDate={props.minDate ?? dayjs()}
                        minTime={props.minTime ?? dayjs()}
                        views={props.views ?? []}
                        ampm={props.ampm ?? false}
                        format={props.format ?? DATE_FORMAT}
                        value={input.value ? dayjs(input.value) : null}
                        slotProps={{ textField: { size: 'small' } }}
                        onChange={(date) => {
                            input.onChange(date);
                        }}
                    />
                </LocalizationProvider>
            )}
        </Field>
    );
};

export default FormDatePicker;

import { GridRowParams } from '@mui/x-data-grid';
import ActionCell from '../../../components/common/ActionCell/ActionCell';
import EditIcon from '../../../components/Icons/EditIcon';
import { permissions } from '../../../utils/AuthHelper/types';
import DeleteForeverIcon from '../../../components/Icons/DeleteForeverIcon';
import ContactPageIcon from '../../../components/Icons/ContactPageIcon';

interface CampaignStepsButtonsProps {
    record: GridRowParams;
    onEditClickHandler: (record: GridRowParams) => void;
    onDeleteClickHandler: (record: GridRowParams) => void;
    onShowPeopleInStepClickHandler: (record: GridRowParams) => void;
}

const CampaignStepsButtons = (
    props: CampaignStepsButtonsProps
): JSX.Element[] => {
    const actions: JSX.Element[] = [];

    if (props.record.row.status === 'new') {
        actions.push(
            <ActionCell
                icon={<EditIcon />}
                record={props.record}
                enablePermissions={permissions.campaignsWrite}
                onClickHandler={props.onEditClickHandler}
            />
        );
        actions.push(
            <ActionCell
                icon={<DeleteForeverIcon />}
                record={props.record}
                enablePermissions={permissions.campaignsDelete}
                onClickHandler={props.onDeleteClickHandler}
            />
        );
    }
    actions.push(
        <ActionCell
            icon={<ContactPageIcon />}
            record={props.record}
            enablePermissions={permissions.campaignsAny}
            onClickHandler={props.onShowPeopleInStepClickHandler}
        />
    );

    return actions;
};

export default CampaignStepsButtons;

import { useNavigate } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { permissions } from '../../utils/AuthHelper/types';
import { RoutePaths } from '../../components/core/AppRouter';
import { styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CampaignStepRecord } from '../../models/CampaignStep';
import Alerts, { AlertObj } from '../../components/Alerts/Alerts';
import { CampaignModel } from '../../models/Campaign';
import CampaignAPI from '../../api/CampaignAPI';
import { SessionContext, SessionContextType } from '../../contexts/session';
import { StoreContext } from '../../contexts/store';
import CampaignStepsHeader from './components/CampaignStepsHeader';
import Card from '../../components/common/Card/Card';
import List from '../../components/common/List/List';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import CampaignStepsAPI from '../../api/CampaignStepsAPI';
import Logger from '../../utils/logger';
import CampaignStepsButtons from './components/CampaignStepsButtons';
import CampaignStepForm, {
    EditCampaignStepInitialValues
} from './components/CampaignStepForm';
import {
    CampaignStepTargetAudience,
    CampaignStepTrigger
} from '@advocate-insights/ms-common';
import { ApiErrorObject } from '../../api/types';
import { EmailTemplateRecord } from '../../models/EmailTemplate';
import { EmailTemplatesContext } from '../../contexts/emailTemplates';
import SubmissionAPI from '../../api/SubmissionAPI';
import { SurveyTemplateRecord } from '../../models/SurveyTemplate';
import SurveyTemplateAPI from '../../api/SurveyTemplateAPI';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { getCurrentTimezone } from '../../utils/tz';

dayjs.extend(timezone);

interface CampaignStepsProps {
    id?: string;
}

const CampaignSteps = (props: CampaignStepsProps): JSX.Element => {
    usePermissions(permissions.campaignsAny);

    const { session } = useContext<SessionContextType>(SessionContext);
    const { store } = useContext(StoreContext);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [campaign, setCampaign] = useState<CampaignModel>();
    const [campaignSteps, setCampaignSteps] = useState<CampaignStepRecord[]>(
        []
    );
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [editInitialValues, setEditInitialValues] =
        useState<EditCampaignStepInitialValues>({
            id: '',
            order: 0,
            name: '',
            surveyId: '',
            emailTemplateId: '',
            targetAudience: CampaignStepTargetAudience.Campaign,
            trigger: CampaignStepTrigger.CampaignStarted,
            status: ''
        });
    const navigate = useNavigate();
    const campaignId = props.id;
    const [emailTemplates, setEmailTemplates] = useState<EmailTemplateRecord[]>(
        []
    );
    const [surveys, setSurveys] = useState<SurveyTemplateRecord[]>([]);

    if (!campaignId) {
        navigate(`/${RoutePaths.Campaigns}`);
    }

    const getEmailTemplates = async () => {
        if (session.idToken && store.currentClientId) {
            try {
                const templates = await SubmissionAPI.getAllEmailTemplates(
                    session,
                    store.currentClientId
                );
                setEmailTemplates(templates);
            } catch (err: unknown) {
                const errNo = await Logger.error(JSON.stringify(err));
                setAlerts([
                    ...alerts,
                    {
                        severity: 'error',
                        text: `[${errNo}] Could not get email templates`
                    }
                ]);
            }
        }
    };

    useEffect(() => {
        if (session.idToken && store.currentClientId) {
            getEmailTemplates();
        }
    }, [session.idToken, store.currentClientId]);

    // Get campaign
    useEffect(() => {
        const getCampaign = async (): Promise<void> => {
            setLoading(true);
            const campaign = await CampaignAPI.get(session, String(campaignId));

            if (!campaign || campaign.clientId !== store.currentClientId) {
                navigate(`/${RoutePaths.Campaigns}`);
                setLoading(false);
                return;
            }

            setCampaign(campaign);
            setLoading(false);
        };

        if (store.currentClientId && campaignId && !campaign) {
            getCampaign();
        }
    }, [store.currentClientId]);

    // Load campaign steps
    useEffect(() => {
        const getCampaignSteps = async () => {
            setLoading(true);
            try {
                const campaigns = await CampaignStepsAPI.getMany(
                    session,
                    campaign?.id
                );
                setCampaignSteps(campaigns.data);
            } catch (error: unknown) {
                const errorId = await Logger.error(JSON.stringify(error));
                setAlerts([
                    ...alerts,
                    {
                        severity: 'error',
                        text: `[${errorId}]: Unknown error retrieving campaigns steps data. Please try again or contact support.`
                    }
                ]);
            } finally {
                setLoading(false);
            }
        };

        if (campaign) {
            getCampaignSteps();
        }
    }, [campaign]);

    // Get survey templates
    useEffect(() => {
        if (session.idToken && store.currentClientId) {
            const getSurveys = async () => {
                try {
                    const surveys = await SurveyTemplateAPI.getAllTemplates(
                        session,
                        store.currentClientId!
                    );
                    setSurveys(surveys);
                } catch (err: unknown) {
                    const errNo = await Logger.error(JSON.stringify(err));
                    setAlerts([
                        ...alerts,
                        {
                            severity: 'error',
                            text: `[${errNo}] Could not get surveys`
                        }
                    ]);
                }
            };

            getSurveys();
        }
    }, [session.idToken, store.currentClientId]);

    const onEditClickHandler = (record: GridRowParams) => {
        if (record.row.id) {
            const initialValues: EditCampaignStepInitialValues = {
                id: record.row.id,
                order: record.row.order,
                name: record.row.name,
                surveyId: record.row.surveyId,
                emailTemplateId: record.row.emailTemplateId,
                targetAudience: record.row.targetAudience,
                trigger: record.row.trigger,
                status: record.row.status
            };

            if (record.row.triggerDays) {
                initialValues.triggerDays = record.row.triggerDays;
            }
            if (!record.row.triggerProperties) {
                initialValues.triggerTimezone = getCurrentTimezone()!.ianaId;
            }
            if (record.row.triggerProperties) {
                initialValues.triggerSchedule =
                    record.row.triggerProperties.triggerDateTime;
                initialValues.triggerTimezone =
                    record.row.triggerProperties.timezone;
            }

            setEditInitialValues(initialValues);
            setEditOpen(true);
        }
    };

    const onDeleteClickHandler = async (
        record: GridRowParams
    ): Promise<void | ApiErrorObject> => {
        const response = await CampaignStepsAPI.delete(session, record.row.id);

        if (!response) {
            setCampaignSteps(
                campaignSteps
                    .filter((step) => step.id !== record.row.id)
                    .map((step) => {
                        if (step.order > record.row.order) {
                            step.order -= 1;
                        }

                        return step;
                    })
            );
            return;
        }

        return response;
    };

    const onShowPeopleInStepClickHandler = async (
        record: GridRowParams
    ): Promise<void | ApiErrorObject> => {
        if (record.row.id) {
            navigate(
                `/${RoutePaths.PeopleInCampaignStep}?campaignStepId=${record.row.id}`
            );
        }
    };

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'surveyId',
            headerName: 'Survey',
            flex: 1,
            valueGetter: (params) => {
                return surveys.find(
                    (survey) => survey.id === params.row.surveyId
                )?.name;
            }
        },
        {
            field: 'emailTemplateId',
            headerName: 'Email template',
            flex: 1,
            valueGetter: (params) => {
                return emailTemplates.find(
                    (emailTemplate) =>
                        emailTemplate.id === params.row.emailTemplateId
                )?.name;
            }
        },
        { field: 'targetAudience', headerName: 'Audience', width: 150 },
        {
            field: 'trigger',
            headerName: 'Trigger',
            width: 150,
            renderCell: (params) => {
                if (params.row.trigger === CampaignStepTrigger.NoResponse) {
                    const campaignStep = campaignSteps.find(
                        (step) => step.id === params.row.id
                    );
                    if (campaignStep) {
                        return (
                            <div>
                                No Response <br /> After
                                {' ' + campaignStep?.triggerDays + ' '}
                                days
                            </div>
                        );
                    }
                }
                return params.row.trigger;
            }
        },
        { field: 'status', headerName: 'Status', width: 100 },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 150,
            align: 'left',
            getActions: (record: GridRowParams) => {
                return CampaignStepsButtons({
                    record,
                    onEditClickHandler,
                    onDeleteClickHandler,
                    onShowPeopleInStepClickHandler
                });
            }
        }
    ];

    return (
        <Container>
            <EmailTemplatesContext.Provider
                value={{ emailTemplates, setEmailTemplates }}
            >
                <Alerts alerts={alerts} setAlerts={setAlerts} />
                <CampaignStepsHeader
                    campaign={campaign}
                    campaignSteps={campaignSteps}
                    setCampaignSteps={setCampaignSteps}
                />
                <Card sx={{ height: '100%' }}>
                    <List<CampaignStepRecord>
                        columns={columns}
                        rows={campaignSteps}
                        loading={loading}
                    />
                </Card>
                {campaign && (
                    <CampaignStepForm
                        open={editOpen}
                        setOpen={setEditOpen}
                        campaignId={campaign.id}
                        campaignSteps={campaignSteps}
                        setCampaignSteps={setCampaignSteps}
                        title='Edit Campaign Step'
                        submitButtonText='Save'
                        initialValues={editInitialValues}
                    />
                )}
            </EmailTemplatesContext.Provider>
        </Container>
    );
};

const Container = styled('div')({
    marginTop: '1rem',
    padding: '0 2rem',
    height: '100%'
});

export default CampaignSteps;

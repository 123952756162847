import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import { validateFormValues } from '../../../utils/forms/validation';
import { SessionContext } from '../../../contexts/session';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import Overlay from '../../../components/core/Overlay/Overlay';
import FormTitle from '../../../components/forms/FormTitle';
import FormRowContainer from '../../../components/forms/FormRowContainer';
import FormTextField from '../../../components/forms/FormTextField';
import FormButton from '../../../components/forms/FormButton';
import { NewPerson, PersonModel } from '../../../models/Person';
import PersonAPI, { PersonRecordAPI } from '../../../api/PersonAPI';
import { CompanyModel } from '../../../models/Company';
import CompanyAPI from '../../../api/CompanyAPI';
import { ObjectType } from '@advocate-insights/ms-common';
import { StoreContext } from '../../../contexts/store';
import { ApiErrorObject } from '../../../api/types';
import CustomDialog from '../../../components/common/CustomDialog/CustomDialog';
import usePermissions from '../../../hooks/usePermissions';
import { permissions } from '../../../utils/AuthHelper/types';
import FormAutocompleteTextField from '../../../components/forms/FormAutocompleteTextField';
import { NewCompany } from '../../../models/Company';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { isUUIDv1 } from '../../../utils/forms/validation';

interface AddPersonProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    people?: PersonModel[];
    setPeople?: (people: PersonModel[]) => void;
    title?: string;
    submitButtonText?: string;
    initialValues?: EditPersonInitialValues;
    onItemAddedHandler?:
        | ((person: PersonRecordAPI) => Promise<void>)
        | (() => void);
}

export interface EditPersonInitialValues {
    id: string;
    email: string;
    name: string;
    lastName?: string;
    companyId?: string;
    doNotContact?: boolean;
}

const PersonForm = (props: AddPersonProps): JSX.Element => {
    usePermissions(permissions.peopleWrite);

    const [processing, setProcessing] = useState<boolean>(false);
    const { session } = useContext(SessionContext);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [companies, setCompanies] = useState<CompanyModel[]>([]);
    const { store } = useContext(StoreContext);
    const [newCompanyName, setNewCompanyName] = useState<string>('');
    const [doNotContact, setDoNotContact] = useState<boolean>(
        props.initialValues?.doNotContact ?? false
    );

    const validate = validateFormValues(
        yup.object().shape({
            email: yup
                .string()
                .email()
                .required()
                .test(
                    'email-doesnot-exist',
                    'Email already exists',
                    async (email) => {
                        if (
                            !yup.string().email().isValidSync(email) ||
                            (props.initialValues &&
                                email === props.initialValues?.email)
                        ) {
                            return true;
                        }

                        const people = await PersonAPI.getMany(session, {
                            clientId: String(store.currentClientId),
                            email: email
                        });

                        return people.count <= 0;
                    }
                ),
            name: yup.string().min(2).required(),
            lastName: yup.string().min(2).optional(),
            companyId: yup.string().optional()
        })
    );

    const getAllCompanies = async (): Promise<void> => {
        setProcessing(true);

        try {
            // @todo ensure we get all companies
            const companies = await CompanyAPI.getAll(session, {
                clientId: String(store.currentClientId)
            });
            setCompanies(companies);
        } finally {
            setProcessing(false);
        }
    };

    useEffect(() => {
        if (store.currentClientId) {
            getAllCompanies();
        }
    }, [store.currentClientId]);

    const onSubmitHandler = async (values: ObjectType): Promise<unknown> => {
        setProcessing(true);

        const personData: NewPerson = {
            email: String(values.email),
            name: String(values.name),
            clientId: String(store.currentClientId)
        };

        if (values.lastName) {
            personData.lastName = String(values.lastName);
        }

        if (doNotContact) {
            personData.doNotContact = doNotContact;
        } else {
            delete personData.doNotContact;
        }

        if (session.user?.clientId) {
            personData.clientId = session.user.clientId;
        }

        if (newCompanyName) {
            const companyData: NewCompany = {
                name: newCompanyName,
                clientId: String(store.currentClientId)
            };

            const newCompany = await CompanyAPI.create(session, companyData);

            if (newCompany && newCompany.id) {
                personData.companyId = newCompany.id;
                companies.push(newCompany as CompanyModel);
            }
        } else if (values.companyId) {
            // this check was added because when person is on edit mode and company not changed
            // the value.companyId is not the uuid but the Company´s name and the update endpoint
            // will return error.
            if (isUUIDv1(String(values.companyId))) {
                personData.companyId = String(values.companyId);
            } else {
                if (props.initialValues?.companyId) {
                    personData.companyId = props.initialValues?.companyId;
                }
            }
        }

        let person: ApiErrorObject | PersonRecordAPI = {};
        if (props.initialValues && props.initialValues.id) {
            const personToUpdate: PersonRecordAPI = {
                ...personData,
                id: props.initialValues.id
            };

            person = await PersonAPI.update(session, personToUpdate);
        } else {
            person = await PersonAPI.create(
                session,
                personData,
                Boolean(personData.companyId && newCompanyName)
            );
        }

        setNewCompanyName('');

        if (person.id) {
            if (person.companyId) {
                person.companyName = String(
                    companies.find((comp) => comp.id === person.companyId)?.name
                );
            }

            if (props.people && props.setPeople) {
                // remove the edited object from people array
                const filteredPersons = props.people.filter(
                    (p) => p.id !== person.id
                );
                props.setPeople([
                    ...filteredPersons,
                    person as PersonRecordAPI
                ]);
            }

            // call onItemAddedHandler to refresh grid on People.tsx
            if (props.onItemAddedHandler) {
                await props.onItemAddedHandler(person as PersonRecordAPI);
            }

            setProcessing(false);
            props.setOpen(false);
            return;
        }

        setProcessing(false);
        return person;
    };

    const onCloseHandler = (): void => {
        setNewCompanyName('');
        props.setOpen(false);
    };

    const onCompanyCreatedHandler = (value: string) => {
        setNewCompanyName(value);
    };

    return (
        <CustomDialog open={props.open} onClose={onCloseHandler}>
            <Overlay processing={processing} />
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Form
                initialValues={props.initialValues || {}}
                onSubmit={onSubmitHandler}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine }) => (
                    <form {...form} id='join-now-form' onSubmit={handleSubmit}>
                        <FormTitle text={props.title || 'Add Contact'} />
                        <FormRowContainer>
                            <FormTextField
                                name='name'
                                label='First Name'
                                fullWidth
                                required
                                autoFocus
                            />
                            <FormTextField
                                name='lastName'
                                label='Last Name'
                                fullWidth
                            />
                        </FormRowContainer>
                        <FormRowContainer>
                            <FormTextField
                                name='email'
                                label='Email'
                                fullWidth
                                required
                            />
                        </FormRowContainer>
                        <FormAutocompleteTextField
                            name='companyId'
                            label='Company (optional)'
                            fullWidth
                            sx={{ mb: 2 }}
                            options={companies?.map((value) => {
                                return {
                                    label: value.name,
                                    key: value.id
                                };
                            })}
                            onEntityCreated={onCompanyCreatedHandler}
                            initialValue={props.initialValues?.companyId ?? ''}
                            placement='top-start'
                        />
                        <FormControlLabel
                            control={<Checkbox />}
                            checked={doNotContact}
                            onChange={(
                                _: React.SyntheticEvent,
                                checked: boolean
                            ) => {
                                setDoNotContact(checked);
                            }}
                            name='doNotContact'
                            label='Do not contact'
                        />
                        <FormRowContainer>
                            <FormButton
                                disabled={submitting || pristine}
                                type='submit'
                            >
                                {props.submitButtonText || 'Add'}
                            </FormButton>
                        </FormRowContainer>
                    </form>
                )}
            />
        </CustomDialog>
    );
};

export default PersonForm;

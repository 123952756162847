import { Button } from '@mui/material';
import { useState } from 'react';
import { ContactModel } from '../../../models/Person';
import CableIcon from '../../Icons/CableIcon';
import ImportContactsCRMForm from './ImportContactsCRMForm';

interface ImportContactsCSVButtonProps {
    onContactsAdded?:
        | ((
              contactsAdded: ContactModel[],
              contactDuplicates: ContactModel[]
          ) => void)
        | (() => void);
}

const ImportContactsCRMButton = (
    props: ImportContactsCSVButtonProps
): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);

    const onClickHandler = (): void => {
        setOpen(true);
    };

    const onContactsAddedHandler = (
        contactsAdded: ContactModel[],
        contactDuplicates: ContactModel[]
    ) => {
        if (props.onContactsAdded) {
            props.onContactsAdded(contactsAdded, contactDuplicates);
        }
    };

    return (
        <>
            <Button
                onClick={onClickHandler}
                sx={{ ml: 1 }}
                variant='contained'
                startIcon={<CableIcon fontSize='small' />}
            >
                Import CRM
            </Button>
            <ImportContactsCRMForm
                open={open}
                setOpen={setOpen}
                onContactsAdded={onContactsAddedHandler}
            />
        </>
    );
};

export default ImportContactsCRMButton;

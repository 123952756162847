import {
    GridColDef,
    GridPaginationModel,
    GridValidRowModel
} from '@mui/x-data-grid';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SessionContext, SessionContextType } from '../../contexts/session';
import Logger from '../../utils/logger';
import Alerts, { AlertObj } from '../../components/Alerts/Alerts';
import { Link, styled } from '@mui/material';
import Card from '../../components/common/Card/Card';
import List, { PageSize } from '../../components/common/List/List';
import { permissions } from '../../utils/AuthHelper/types';
import usePermissions from '../../hooks/usePermissions';
import {
    CampaignModel,
    PersonInCampaignRecord,
    personInCampaignStatusMapping
} from '../../models/Campaign';
import CampaignAPI from '../../api/CampaignAPI';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../components/core/AppRouter';
import SurveyResponseView from '../peopleInCampaignStep/components/SurveyResponseView';
import PeopleInCampaignController from './PeopleInCampaignController';
import {
    ObjectType,
    PaginatedCollection,
    PersonInCampaignEventStatus
} from '@advocate-insights/ms-common';
import PeopleInCampaignHeader from './components/PeopleInCampaignHeader';

const RETRIES = 3;

interface PeopleInCampaignProps {
    id?: string;
}

const PeopleInCampaign = (props: PeopleInCampaignProps): JSX.Element => {
    const navigate = useNavigate();

    if (!props.id) {
        navigate(`/${RoutePaths.Campaigns}`);
        return <></>;
    }

    usePermissions(permissions.campaignsAny);

    const campaignId = props.id;
    const { session } = useContext<SessionContextType>(SessionContext);
    const [campaign, setCampaign] = useState<CampaignModel>();
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [responseOpen, setResponseOpen] = useState<boolean>(false);
    const [responseId, setResponseId] = useState<string>('');
    const [paginationModel, setPaginationModel] = useState<{
        pageSize: number;
        page: number;
    }>({
        pageSize: PageSize,
        page: 0
    });
    const [peopleInCampaign, setPeopleInCampaign] = useState<
        PersonInCampaignRecord[]
    >([]);

    const [rowCount, setRowCount] = useState<number>(0);
    const [pageKeys, setPageKeys] = useState<ObjectType[]>([]);

    const getPeopleInCampaign = useCallback(async () => {
        if (campaignId) {
            setLoading(true);
            let retries = RETRIES;
            let succeded = false;
            while (retries && !succeded) {
                try {
                    const peopleInCampaign: PaginatedCollection<PersonInCampaignRecord> =
                        await PeopleInCampaignController.getPeopleInCampaign(
                            session,
                            campaignId
                        );
                    setRowCount(peopleInCampaign.count);
                    setPeopleInCampaign(peopleInCampaign.data);
                    setPageKeys([...pageKeys, peopleInCampaign.lastKey || {}]);
                    setPaginationModel({
                        pageSize: PageSize,
                        page: 0
                    });
                    succeded = true;
                } catch (err: unknown) {
                    Logger.error(String(err));
                }
                retries--;
            }
            if (!succeded) {
                setAlerts([
                    ...alerts,
                    {
                        severity: 'error',
                        text: 'Unable to retrieve contacts in campaign. Retry max attempts reached'
                    }
                ]);
            }
            setLoading(false);
        }
    }, [session.idToken]);

    // Load people in campaign
    useEffect(() => {
        if (session.idToken) {
            getPeopleInCampaign();
        }
    }, [campaignId, session.idToken]);

    const getCampaign = useCallback(async (): Promise<void> => {
        setLoading(true);

        try {
            const campaign = await CampaignAPI.get(session, campaignId);

            setCampaign(campaign);
        } catch (err: unknown) {
            navigate(`/${RoutePaths.Campaigns}`);
        } finally {
            setLoading(false);
        }
    }, [session.idToken]);

    // Get campaign
    useEffect(() => {
        if (session.idToken) {
            getCampaign();
        }
    }, [session.idToken]);

    const columns: GridColDef[] = useMemo(() => {
        const cols: GridColDef[] = [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                valueGetter: (params) => {
                    return `${params.row.name} ${
                        params.row.lastName || ''
                    }`.trim();
                }
            },
            { field: 'email', headerName: 'Email', flex: 1 },
            {
                field: 'companyName',
                headerName: 'Company',
                flex: 1
            }
        ];

        if (campaign?.status && campaign.status !== 'new') {
            cols.push({
                field: 'status',
                headerName: 'Status',
                flex: 1,
                valueGetter: (params) => {
                    return (
                        personInCampaignStatusMapping[
                            params.row.status as PersonInCampaignEventStatus
                        ] || ''
                    );
                }
            });

            cols.push({
                field: 'response',
                headerName: 'Response',
                flex: 1,
                renderCell: (params): JSX.Element => {
                    if (params.row.response) {
                        const responses = JSON.parse(params.row.response);

                        if (
                            responses &&
                            typeof responses === 'object' &&
                            Object.keys(responses)[0]
                        ) {
                            return (
                                <Link
                                    onClick={() =>
                                        onResponseClickHandler(
                                            String(Object.keys(responses)[0])
                                        )
                                    }
                                    sx={{ cursor: 'pointer' }}
                                >
                                    View Response
                                </Link>
                            );
                        }
                    }

                    return <></>;
                }
            });
        }

        return cols;
    }, [campaign]);

    const onResponseClickHandler = (responseId: string): void => {
        setResponseId(responseId);
        setResponseOpen(true);
    };

    const onPaginationModelChanged = async (model: GridPaginationModel) => {
        if (campaignId) {
            setLoading(false);
            setPaginationModel({ pageSize: PageSize, page: model.page });

            PeopleInCampaignController.getPeopleInCampaign(
                session,
                campaignId,
                model.page === 0 || !pageKeys[model.page - 1]
                    ? ''
                    : pageKeys[model.page - 1]
            )
                .then(
                    (
                        peopleInCampaign: PaginatedCollection<PersonInCampaignRecord>
                    ) => {
                        setRowCount(peopleInCampaign.count);
                        setPeopleInCampaign(peopleInCampaign.data);

                        const keyIdx = pageKeys.findIndex((p) => {
                            return (
                                JSON.stringify(p) ===
                                JSON.stringify(peopleInCampaign.lastKey)
                            );
                        });
                        if (peopleInCampaign.lastKey && keyIdx === -1) {
                            setPageKeys([
                                ...pageKeys,
                                peopleInCampaign.lastKey
                            ]);
                        }

                        setPageKeys([
                            ...pageKeys,
                            peopleInCampaign.lastKey || {}
                        ]);
                        setPaginationModel({
                            pageSize: PageSize,
                            page: model.page
                        });
                    }
                )
                .catch((err: unknown) => {
                    Logger.error(JSON.stringify(err)).then(
                        (errorId: string) => {
                            setAlerts([
                                ...alerts,
                                {
                                    severity: 'error',
                                    text: `[${errorId}]: Unknown error retrieving contacts data. Please try again or contact support.`
                                }
                            ]);
                        }
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <Container>
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <PeopleInCampaignHeader
                campaign={campaign}
                refreshPeopleInCampaign={getPeopleInCampaign}
                peopleInCampaign={peopleInCampaign}
            />
            <Card sx={{ height: '100%' }}>
                <List<PersonInCampaignRecord>
                    columns={columns}
                    rows={peopleInCampaign}
                    loading={loading}
                    paginationModel={paginationModel}
                    rowCount={rowCount}
                    onPaginationModelChange={onPaginationModelChanged}
                    getRowId={(row: GridValidRowModel) => row.personId}
                    pageSizeOptions={[PageSize]}
                />
            </Card>
            <SurveyResponseView
                submissionId={responseId}
                open={responseOpen}
                setOpen={setResponseOpen}
            />
        </Container>
    );
};

const Container = styled('div')({
    marginTop: '1rem',
    padding: '0 2rem',
    height: '100%'
});

export default PeopleInCampaign;

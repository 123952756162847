import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { APIGetPaginatedParams, ApiError, ApiErrorType } from './types';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import { CampaignWithMetricsRecord } from '../models/CampaignWithMetrics';

export interface CampaignMetricsAPIGetPaginatedParams
    extends APIGetPaginatedParams {
    clientId?: string;
}

class CampaignMetricsAPI {
    private static getCampaignMetricsAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_CAMPAIGN_METRICS_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static getMany = async (
        session: SessionType,
        params: CampaignMetricsAPIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<CampaignWithMetricsRecord>> => {
        const campaignAPI = this.getCampaignMetricsAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params };

        if (params.lastKey) {
            filter.params.lastKey = JSON.stringify(params.lastKey);
        }

        const { status, data } = await campaignAPI.get<
            PaginatedCollection<CampaignWithMetricsRecord>
        >('', filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving campaign metrics');
        }
    };

    public static getAll = async (
        session: SessionType,
        clientId: string
    ): Promise<CampaignWithMetricsRecord[]> => {
        let campaigns: CampaignWithMetricsRecord[] = [];
        const processNext = true;
        let iterationLimit = 20;
        const pageParams: CampaignMetricsAPIGetPaginatedParams = {
            clientId
        };

        while (processNext && iterationLimit) {
            const companyPage = await this.getMany(session, pageParams);

            if (companyPage.count > 0) {
                campaigns = [...campaigns, ...companyPage.data];
            }

            if (!companyPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = companyPage.lastKey;
        }

        if (iterationLimit <= 0) {
            throw new ApiError(
                'CampaignMetricsAPI.getAll() reached its iteration limit',
                ApiErrorType.IterationLimit
            );
        }

        return campaigns;
    };
}

export default CampaignMetricsAPI;

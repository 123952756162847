import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import {
    APIGetPaginatedParams,
    ApiError,
    ApiErrorObject,
    ApiErrorType
} from './types';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import {
    EmailTemplateRecord,
    NewEmailTemplate,
    EmailTemplatePreviewRecord
} from '../models/EmailTemplate';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';

export interface SubmissionSurveyResponse {
    surveyId: string;
    campaignId: string;
}

export interface EmailTemnplatesGetPaginatedParams
    extends APIGetPaginatedParams {
    clientId?: string;
}

class SubmissionAPI {
    private static getSubmissionAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_SUBMISSION_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static getSurvey = async (
        submissionId: string
    ): Promise<SubmissionSurveyResponse> => {
        const submissionAPI = this.getSubmissionAPIAxiosObj();

        const { status, data } =
            await submissionAPI.get<SubmissionSurveyResponse>(
                `/submissions/${submissionId}/survey`
            );

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error(
                `Error retrieving survey from submission id ${submissionId}`
            );
        }
    };

    public static getManyEmailTemplates = async (
        session: SessionType,
        params: EmailTemnplatesGetPaginatedParams = {}
    ): Promise<PaginatedCollection<EmailTemplateRecord>> => {
        const templateAPI = this.getSubmissionAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params };

        if (params.lastKey) {
            filter.params.lastKey = JSON.stringify(params.lastKey);
        }

        const { status, data } = await templateAPI.get<
            PaginatedCollection<EmailTemplateRecord>
        >('/templates', filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving email templates');
        }
    };

    public static getAllEmailTemplates = async (
        session: SessionType,
        clientId: string
    ): Promise<EmailTemplateRecord[]> => {
        let templates: EmailTemplateRecord[] = [];
        const processNext = true;
        let iterationLimit = 20;
        const pageParams: EmailTemnplatesGetPaginatedParams = {
            clientId
        };

        while (processNext && iterationLimit) {
            const companyPage = await this.getManyEmailTemplates(
                session,
                pageParams
            );

            if (companyPage.count > 0) {
                templates = [...templates, ...companyPage.data];
            }

            if (!companyPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = companyPage.lastKey;
        }

        if (iterationLimit <= 0) {
            throw new ApiError(
                'SubmissionAPI.getAllEmailTemplates() reached its iteration limit',
                ApiErrorType.IterationLimit
            );
        }

        return templates;
    };

    public static createEmailTemplate = async (
        session: SessionType,
        templateData: NewEmailTemplate
    ): Promise<ApiErrorObject | EmailTemplateRecord> => {
        const submissionAPI = this.getSubmissionAPIAxiosObj(session);

        try {
            const template = await submissionAPI.post<EmailTemplateRecord>(
                '/templates',
                templateData
            );

            if (template.data) {
                return template.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('creating email template');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('creating email template');
    };

    public static updateEmailTemplate = async (
        session: SessionType,
        template: EmailTemplateRecord
    ): Promise<ApiErrorObject | EmailTemplateRecord> => {
        const submissionAPI = this.getSubmissionAPIAxiosObj(session);

        try {
            const updatedTemplate =
                await submissionAPI.put<EmailTemplateRecord>(
                    `/templates/${template.id}`,
                    template
                );

            if (updatedTemplate.data) {
                return updatedTemplate.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('updating email template');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('updating email template');
    };

    public static sendPreviewEmailTemplate = async (
        session: SessionType,
        templateData: NewEmailTemplate
    ): Promise<ApiErrorObject | EmailTemplatePreviewRecord> => {
        const submissionAPI = this.getSubmissionAPIAxiosObj(session);

        try {
            const template =
                await submissionAPI.post<EmailTemplatePreviewRecord>(
                    '/templates/preview',
                    templateData
                );

            if (template.data) {
                return template.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError(
                    'sending preview email template'
                );
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('sending preview email template');
    };
}

export default SubmissionAPI;

import axios, {
    AxiosError,
    AxiosInstance,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import {
    NewSurveyResponse,
    SurveyResponseRecord
} from '../models/SurveyResponse';
import { ApiErrorObject } from './types';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';

class SurveyResponseAPI {
    private static getResponseAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_SURVEY_RESPONSE_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static submit = async (
        responseData: NewSurveyResponse
    ): Promise<ApiErrorObject | undefined> => {
        const surveyResponseAPI = SurveyResponseAPI.getResponseAPIAxiosObj();

        try {
            await surveyResponseAPI.post('', responseData);
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('submitting survey response');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return;
    };

    public static get = async (
        session: SessionType,
        submissionId: string
    ): Promise<SurveyResponseRecord> => {
        const surveyAPI = SurveyResponseAPI.getResponseAPIAxiosObj(session);

        const { status, data } = await surveyAPI.get<SurveyResponseRecord>(
            `/${submissionId}`
        );

        if (status === 200 && data && data.id) {
            return data;
        } else {
            throw new Error(
                `Error retrieving survey response with id ${submissionId}`
            );
        }
    };

    public static validate = async (submissionId: string): Promise<boolean> => {
        const surveyAPI = SurveyResponseAPI.getResponseAPIAxiosObj();

        const { status, data } = await surveyAPI.get<boolean>(
            `/${submissionId}/validate`
        );

        if (status === 200 && typeof data === 'boolean') {
            return data;
        } else {
            throw new Error(`Error validating submission id ${submissionId}`);
        }
    };
}

export default SurveyResponseAPI;

import { Grid, Button } from '@mui/material';
import { CampaignModel } from '../../../models/Campaign';
import AuthHelper from '../../../utils/AuthHelper/AuthHelper';
import { useContext, useState } from 'react';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import { permissions } from '../../../utils/AuthHelper/types';
import { StoreContext } from '../../../contexts/store';
import AddIcon from '../../../components/Icons/AddIcon';
import CampaignStepForm from './CampaignStepForm';
import { CampaignStepEventStatus } from '@advocate-insights/ms-common';
import { CampaignStepRecord } from '../../../models/CampaignStep';
import usePermissions from '../../../hooks/usePermissions';
import {
    EmailTemplatesContext,
    EmailTemplatesContextType
} from '../../../contexts/emailTemplates';

interface PeopleInCampaignHeaderProps {
    campaign: CampaignModel | undefined;
    campaignSteps: CampaignStepRecord[];
    setCampaignSteps: (steps: CampaignStepRecord[]) => void;
}

const CampaignStepsHeader = (
    props: PeopleInCampaignHeaderProps
): JSX.Element => {
    usePermissions(permissions.campaignsAny);

    const { session } = useContext<SessionContextType>(SessionContext);
    const { store } = useContext(StoreContext);
    const [open, setOpen] = useState<boolean>(false);
    const { emailTemplates } = useContext<EmailTemplatesContextType>(
        EmailTemplatesContext
    );

    const onClickHandler = (): void => {
        setOpen(true);
    };

    return (
        <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            sx={{ mb: 2 }}
        >
            <Grid item>
                {AuthHelper.authorize(session, permissions.campaignsWrite) &&
                    props.campaign?.status === CampaignStepEventStatus.New &&
                    store.currentClientId &&
                    emailTemplates.length > 0 && (
                        <>
                            <Button
                                onClick={onClickHandler}
                                sx={{ mt: { xs: 2, md: 0 } }}
                                variant='contained'
                                startIcon={<AddIcon fontSize='small' />}
                            >
                                Campaign Step
                            </Button>
                            <CampaignStepForm
                                open={open}
                                setOpen={setOpen}
                                campaignId={String(props.campaign.id)}
                                campaignSteps={props.campaignSteps}
                                setCampaignSteps={props.setCampaignSteps}
                            />
                        </>
                    )}
            </Grid>
        </Grid>
    );
};

export default CampaignStepsHeader;

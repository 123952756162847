import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

interface UploadFileIconProps {
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const UploadFileIcon = (props: UploadFileIconProps): JSX.Element => {
    return <UploadFileOutlinedIcon {...props} />;
};

export default UploadFileIcon;

import {
    CampaignEventStatus,
    ObjectType,
    PersonInCampaignEventStatus
} from '@advocate-insights/ms-common';

export interface NewCampaign extends ObjectType {
    name: string;
    clientId: string;
    userEmail?: string[];
}

export interface CampaignRecord extends NewCampaign {
    id: string;
    status: string;
}
export interface CampaignModel extends CampaignRecord {}

export interface PersonInCampaignRecord {
    campaignId: string;
    personId: string;
    status: PersonInCampaignEventStatus | string;
    email: string;
    name: string;
    lastName?: string;
    companyId?: string;
    companyName?: string;
    response?: string;
}

export const personInCampaignStatusMapping: {
    [key in PersonInCampaignEventStatus]: string;
} = {
    [PersonInCampaignEventStatus.New]: 'Contacted',
    [PersonInCampaignEventStatus.Scheduled]: 'Contacted',
    [PersonInCampaignEventStatus.Responded]: 'NPS Submitted'
};

export const campaignStatusMapping: { [key: string]: string } = {
    [CampaignEventStatus.New]: 'New',
    [CampaignEventStatus.Running]: 'Running',
    [CampaignEventStatus.Finalized]: 'Finalized',
    paused: 'Paused'
};

import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { APIGetPaginatedParams, ApiErrorObject } from './types';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import { CampaignStepRecord, NewCampaignStep } from '../models/CampaignStep';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';

import { PersonInStepRecord } from '../models/PersonInStep';

class CampaignStepsAPI {
    private static getCampaignStepsAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_CAMPAIGN_STEPS_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static create = async (
        session: SessionType,
        campaignData: NewCampaignStep
    ): Promise<ApiErrorObject | CampaignStepRecord> => {
        const campaignStepsAPI = this.getCampaignStepsAPIAxiosObj(session);

        try {
            const campaign = await campaignStepsAPI.post<CampaignStepRecord>(
                '',
                campaignData
            );

            if (campaign.data) {
                return campaign.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('creating campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('creating campaign');
    };

    public static update = async (
        session: SessionType,
        campaignData: CampaignStepRecord
    ): Promise<ApiErrorObject | CampaignStepRecord> => {
        const campaignStepAPI = this.getCampaignStepsAPIAxiosObj(session);

        try {
            const updatedCampaignStep =
                await campaignStepAPI.put<CampaignStepRecord>(
                    `/${campaignData.id}`,
                    campaignData
                );

            if (updatedCampaignStep.data) {
                return updatedCampaignStep.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('updating campaign step');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('creating campaign step');
    };

    public static delete = async (
        session: SessionType,
        campaignStepId: string
    ): Promise<ApiErrorObject | void> => {
        const campaignStepAPI = this.getCampaignStepsAPIAxiosObj(session);

        try {
            const response = await campaignStepAPI.delete(`/${campaignStepId}`);

            if (response && response.status === 200) {
                return;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('updating campaign step');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('creating campaign step');
    };

    public static getMany = async (
        session: SessionType,
        campaignId?: string,
        params: APIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<CampaignStepRecord>> => {
        const campaignStepsAPI = this.getCampaignStepsAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params: {} };

        if (campaignId) {
            filter.params = { campaignId };
        }

        if (params) {
            filter.params = { ...filter.params, ...params };
        }

        const { status, data } = await campaignStepsAPI.get<
            PaginatedCollection<CampaignStepRecord>
        >('', filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving campaign steps');
        }
    };

    public static getPeople = async (
        session: SessionType,
        campaignStepId?: string,
        params: APIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<PersonInStepRecord>> => {
        const filter: AxiosRequestConfig = {};
        const campaignStepsAPI = this.getCampaignStepsAPIAxiosObj(session);

        if (params.scanLimit) {
            filter.params.scanLimit = params.scanLimit;
        }
        if (params.lastKey) {
            filter.params.lastKey = JSON.stringify(params.lastKey);
        }
        const { status, data } = await campaignStepsAPI.get<
            PaginatedCollection<PersonInStepRecord>
        >(`/${campaignStepId}/people`, filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving people in campaign step');
        }
    };

    public static get = async (
        session: SessionType,
        campaignStepId?: string
    ): Promise<CampaignStepRecord> => {
        const campaignStepsAPI = this.getCampaignStepsAPIAxiosObj(session);
        const { status, data } = await campaignStepsAPI.get<CampaignStepRecord>(
            `/${campaignStepId}`
        );

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving single campaign step');
        }
    };
}

export default CampaignStepsAPI;

import { Form } from 'react-final-form';
import { ContactModel } from '../../../models/Person';
import Alerts, { AlertObj } from '../../Alerts/Alerts';
import Overlay from '../../core/Overlay/Overlay';
import CustomDialog from '../CustomDialog/CustomDialog';
import { useContext, useMemo, useState } from 'react';
import FormTitle from '../../forms/FormTitle';
import FormRowContainer from '../../forms/FormRowContainer';
import FormTextField from '../../forms/FormTextField';
import { StoreContext } from '../../../contexts/store';
import { ConnectionRecord } from '../../../models/Connection';
import { Divider, MenuItem, Stack, Typography } from '@mui/material';
import FormButton from '../../forms/FormButton';
import ImportContactsCRMHelper from './ImportContactsCRMHelper';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import Logger from '../../../utils/logger';
import CloseDialog from '../CloseDialog/CloseDialog';

interface ImportContactsCRMFormProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onContactsAdded?: (
        contactsAdded: ContactModel[],
        contactDuplicates: ContactModel[]
    ) => void;
}

const ImportContactsCRMForm = (
    props: ImportContactsCRMFormProps
): JSX.Element => {
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [processing, setProcessing] = useState<boolean>(false);
    const { store } = useContext(StoreContext);
    const { session } = useContext<SessionContextType>(SessionContext);
    const [resultsOpen, setResultsOpen] = useState<boolean>(false);
    const [results, setResults] = useState<JSX.Element>(<></>);

    const connections = useMemo<ConnectionRecord[]>(() => {
        return store.connections
            ? store.connections.filter((conn) => {
                  return ImportContactsCRMHelper.connectionIsCollable(conn);
              })
            : [];
    }, [store.connections]);

    const initialValues = useMemo(() => {
        const values: { [key: string]: unknown } = {};

        if (store.connections && store.connections[0]) {
            values.connectionId = store.connections[0].id;
        }

        return values;
    }, [store.connections]);

    const onCloseHandler = () => {
        setProcessing(false);
        setAlerts([]);
        props.setOpen(false);
    };

    const onSubmitHandler = async (values: unknown) => {
        let errMsg = 'Unexpected error importing contacts';

        if (
            store.connections &&
            values &&
            typeof values === 'object' &&
            'connectionId' in values &&
            store.currentClientId
        ) {
            const connection = store.connections.find(
                (conn) => conn.id == values.connectionId
            );

            if (connection) {
                setProcessing(true);

                try {
                    const result = await ImportContactsCRMHelper.import(
                        session,
                        connection,
                        store.currentClientId
                    );

                    if (props.onContactsAdded) {
                        props.onContactsAdded(
                            result.imported,
                            result.duplicates
                        );
                    }

                    setResults(
                        <Stack direction='column'>
                            <Typography>
                                Contacts imported: {result.imported.length}
                            </Typography>
                            <Typography>
                                Duplicated contacts: {result.duplicates.length}
                            </Typography>
                            {Boolean(result.skipped) && (
                                <Typography>
                                    Contacts skipped: {result.skipped}
                                </Typography>
                            )}
                        </Stack>
                    );
                    onCloseHandler();
                    setResultsOpen(true);
                    return;
                } catch (err: unknown) {
                    const errNo = await Logger.error(JSON.stringify(err));
                    errMsg = `[${errNo}]: Unexpected error importing contacts`;
                }
            }
        }

        setAlerts([
            ...alerts,
            {
                severity: 'error',
                text: errMsg
            }
        ]);
    };

    return (
        <>
            <CustomDialog open={props.open} onClose={onCloseHandler}>
                <Overlay processing={processing} />
                <Alerts alerts={alerts} setAlerts={setAlerts} />
                <Form
                    onSubmit={onSubmitHandler}
                    initialValues={initialValues}
                    render={({ handleSubmit, form, values }) => (
                        <form {...form} onSubmit={handleSubmit}>
                            <FormTitle text='Import Contacts from CRM' />
                            <FormRowContainer>
                                <FormTextField
                                    name='connectionId'
                                    label='Integration'
                                    select
                                    fullWidth
                                >
                                    {connections.map((conn) => (
                                        <MenuItem key={conn.id} value={conn.id}>
                                            {conn.service_id}
                                        </MenuItem>
                                    ))}
                                </FormTextField>
                            </FormRowContainer>
                            <Divider sx={{ my: '1rem' }} />
                            <FormRowContainer>
                                <FormButton
                                    disabled={
                                        !values ||
                                        typeof values !== 'object' ||
                                        !('connectionId' in values) ||
                                        !values.connectionId ||
                                        !store.connections ||
                                        !session.idToken ||
                                        !store.currentClientId
                                    }
                                    type='submit'
                                >
                                    Process Import
                                </FormButton>
                            </FormRowContainer>
                        </form>
                    )}
                />
            </CustomDialog>
            <CloseDialog
                open={resultsOpen}
                setOpen={setResultsOpen}
                title='Importing from CRM Integration'
            >
                {results}
            </CloseDialog>
        </>
    );
};

export default ImportContactsCRMForm;

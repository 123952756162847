import styled from '@emotion/styled';
import { Button, ButtonProps, Typography } from '@mui/material';

interface CSVReaderChildFuncProps {
    getRootProps: () => ButtonProps;
    acceptedFile: File | null;
}

const CSVReaderUploadButton = ({
    getRootProps,
    acceptedFile
}: CSVReaderChildFuncProps): JSX.Element => {
    let fileName = acceptedFile?.name ?? '';

    if (fileName.length > 40) {
        fileName =
            fileName.substring(0, 20) +
            '...' +
            fileName.substring(fileName.length - 20);
    }

    return (
        <Container>
            <Button
                color='secondary'
                sx={{ height: '2rem' }}
                variant='contained'
                {...getRootProps()}
            >
                Browse File
            </Button>
            <Typography sx={{ ml: '1rem' }} display='inline'>
                {fileName}
            </Typography>
        </Container>
    );
};

const Container = styled('div')({
    paddingBottom: '1rem'
});

export default CSVReaderUploadButton;

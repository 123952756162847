import { Typography, Grid, Button } from '@mui/material';
import AuthHelper from '../../../utils/AuthHelper/AuthHelper';
import { useContext /*useState*/ } from 'react';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import { permissions } from '../../../utils/AuthHelper/types';
import { StoreContext } from '../../../contexts/store';
import AddIcon from '../../../components/Icons/AddIcon';
import usePermissions from '../../../hooks/usePermissions';
import { ClientModel } from '../../../models/Client';
import DomainForm from './DomainForm';
import { useState } from 'react';

interface DomainsHeaderProps {
    client: ClientModel | undefined;
    onItemAddedHandler: () => void;
}

const DomainsHeader = (props: DomainsHeaderProps): JSX.Element => {
    usePermissions(permissions.clientsWrite);

    const clientName = props.client?.name ?? '';
    const { session } = useContext<SessionContextType>(SessionContext);
    const { store } = useContext(StoreContext);
    const [open, setOpen] = useState<boolean>(false);

    const onClickHandler = (): void => {
        setOpen(true);
    };

    return (
        <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            sx={{ mb: 2 }}
        >
            <Grid item>
                <Typography variant='h3' component='h3' gutterBottom>
                    {`Client: ${clientName}`}
                </Typography>
                {AuthHelper.authorize(session, permissions.clientsWrite) &&
                    store.currentClientId && (
                        <>
                            <Button
                                onClick={onClickHandler}
                                sx={{ mt: { xs: 2, md: 1 }, mb: 1 }}
                                variant='contained'
                                startIcon={<AddIcon fontSize='small' />}
                            >
                                Domain
                            </Button>
                            {open && (
                                <DomainForm
                                    open={open}
                                    setOpen={setOpen}
                                    title='Add Domain'
                                    clientId={props.client?.id ?? ''}
                                    onItemAddedHandler={
                                        props.onItemAddedHandler
                                    }
                                />
                            )}
                        </>
                    )}
            </Grid>
        </Grid>
    );
};

export default DomainsHeader;

import usePermissions from '../../../hooks/usePermissions';
import { permissions } from '../../../utils/AuthHelper/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../components/core/AppRouter';
import PeopleInCampaign from '../../peopleInCampaign/PeopleInCampaign';
import CampaignSteps from '../../campaignSteps/CampaignSteps';
import CommonTabs from '../../../components/common/Tab/CustomTab';
import { Container } from '@mui/material';
import Breadcrumbs, {
    BreadcrumbOption
} from '../../../components/common/Breadcrumbs/Breadcrumbs';
import { useState, useCallback, useContext, useEffect } from 'react';
import CampaignAPI from '../../../api/CampaignAPI';
import { SessionContext, SessionContextType } from '../../../contexts/session';

const CampaignDetails = (): JSX.Element => {
    const queryParams = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();

    if (!queryParams.get('id')) {
        navigate(`/${RoutePaths.Campaigns}`);
        return <></>;
    }
    usePermissions(permissions.campaignsAny);

    const campaignId = queryParams.get('id');

    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbOption[]>();

    const { session } = useContext<SessionContextType>(SessionContext);

    const getCampaign = useCallback(async (): Promise<void> => {
        try {
            if (campaignId) {
                const campaign = await CampaignAPI.get(session, campaignId);
                setBreadcrumbs([
                    { text: 'Home', link: `/${RoutePaths.Campaigns}` },
                    { text: 'Campaigns', link: `/${RoutePaths.Campaigns}` },
                    { text: campaign.name }
                ]);
            }
        } catch (err: unknown) {
            navigate(`/${RoutePaths.Campaigns}`);
        }
    }, [session.idToken]);

    useEffect(() => {
        getCampaign();
    }, []);

    return (
        <Container maxWidth={false} sx={{ paddingTop: '20px' }}>
            <Breadcrumbs options={breadcrumbs ?? []} />
            <CommonTabs
                component1={
                    <PeopleInCampaign id={String(queryParams.get('id'))} />
                }
                component2={
                    <CampaignSteps id={String(queryParams.get('id'))} />
                }
            />
        </Container>
    );
};

export default CampaignDetails;

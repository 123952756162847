import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

const DEFAULT_TIMEZONE = {
    tzId: 'US/Central',
    ianaId: 'America/Chicago'
};

export const TIMEZONES = [
    {
        tzId: 'US/Eastern',
        ianaId: 'America/New_York'
    },
    {
        tzId: 'US/Central',
        ianaId: 'America/Chicago'
    },
    {
        tzId: 'US/Mountain',
        ianaId: 'America/Denver'
    },
    {
        tzId: 'US/Pacific',
        ianaId: 'America/Los_Angeles'
    },
    {
        tzId: 'US/Hawaii',
        ianaId: 'Pacific/Honolulu'
    },
    {
        tzId: 'US/Alaska',
        ianaId: 'America/Anchorage'
    },
    {
        tzId: 'America/Bogota',
        ianaId: 'America/Bogota'
    }
];

export const getCurrentTimezone = () => {
    const currentTz = dayjs.tz.guess();
    const timezone = TIMEZONES.filter((item) => item.ianaId === currentTz);
    if (timezone) {
        return timezone[0];
    }
    // return by default US/Central
    return DEFAULT_TIMEZONE;
};

import {
    GridColDef,
    GridInputRowSelectionModel,
    GridRowSelectionModel
} from '@mui/x-data-grid';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, Grid } from '@mui/material';
import CampaignsController from '../../campaigns/CampaignsController';
import ArrayHelper from '../../../utils/ArrayHelper';
import CampaignAPI from '../../../api/CampaignAPI';
import {
    CampaignModel,
    PersonInCampaignRecord
} from '../../../models/Campaign';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import { PersonModel } from '../../../models/Person';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import { StoreContext } from '../../../contexts/store';
import List, { PageSize } from '../../../components/common/List/List';
import PersonAPI from '../../../api/PersonAPI';
import Logger from '../../../utils/logger';
import Card from '../../../components/common/Card/Card';
import ListTitle from '../../../components/common/List/ListTitle';

interface SelectPeopleInCampaignProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    campaign: CampaignModel;
    refreshPeopleInCampaign: () => void;
    peopleInCampaign: PersonInCampaignRecord[];
}

const SelectPeopleInCampaign = (
    props: SelectPeopleInCampaignProps
): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const [people, setPeople] = useState<PersonModel[]>([]);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { store } = useContext(StoreContext);
    const [selection, setSelection] = useState<GridRowSelectionModel>([]);
    const [paginationModel, setPaginationModel] = useState<{
        pageSize: number;
        page: number;
    }>({
        pageSize: PageSize,
        page: 0
    });

    const getPeople = useCallback(async (): Promise<void> => {
        setLoading(true);
        try {
            const people = await PersonAPI.getMany(session, {
                clientId: String(store.currentClientId)
            });
            setPeople(people.data.filter((data) => !data.doNotContact));
        } catch (error: unknown) {
            const errorId = await Logger.error(JSON.stringify(error));
            setAlerts([
                ...alerts,
                {
                    severity: 'error',
                    text: `[${errorId}]: Unknown error retrieving contacts data. Please try again or contact support.`
                }
            ]);
        } finally {
            setLoading(false);
        }
    }, [session.idToken, store.currentClientId]);

    // Get people in client
    useEffect(() => {
        if (store.currentClientId && session.idToken) {
            getPeople();
        }
    }, [store.currentClientId, session.idToken]);

    // Refresh selection every time the modal opens
    useEffect(() => {
        // This validation along with call to getPeople() will help us
        // to ensure the select contact grid is updated
        if (props.peopleInCampaign.length > selection.length) {
            setSelection(props.peopleInCampaign.map((ppl) => ppl.personId));
            getPeople();
        }
    });

    const onSaveClickHandler = async () => {
        setLoading(true);

        try {
            const unprocessedPeople = await CampaignAPI.assignPeople(
                session,
                props.campaign.id,
                selection as string[]
            );

            if (
                unprocessedPeople instanceof Array &&
                unprocessedPeople.length <= 0
            ) {
                props.refreshPeopleInCampaign();
                props.setOpen(false);
            } else {
                // @todo there's an opportunity to do something better here, lilke retrying
                setAlerts([
                    ...alerts,
                    {
                        severity: 'error',
                        text:
                            'We got some errors assigning contacts to the campaign.' +
                            ` ${unprocessedPeople.length}/${selection.length} could not be processed.`
                    }
                ]);

                return;
            }
        } catch (err: unknown) {
            const errNo = await Logger.error(JSON.stringify(err));
            setAlerts([
                ...alerts,
                {
                    severity: 'error',
                    text: `[${errNo}] Unexpected error saving selected contacts`
                }
            ]);
        } finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            valueGetter: (params) => {
                const firstName = params.row.name;
                const lastName = params.row.lastName ?? '';
                return `${firstName} ${lastName}`.trim();
            }
        },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'companyName',
            headerName: 'Company',
            flex: 1
        }
    ];

    const onCloseHandler = (): void => {
        props.setOpen(false);
    };

    const saveButtonDisabled = useMemo(() => {
        return ArrayHelper.equals(
            selection || [],
            props.peopleInCampaign.map((ppl) => ppl.personId)
        );
    }, [selection]);

    return (
        <Dialog
            open={props.open}
            onClose={onCloseHandler}
            PaperProps={{
                sx: {
                    maxWidth: 'none',
                    maxHeight: 'none'
                }
            }}
        >
            <DialogContent>
                <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ mb: 2 }}
                >
                    <Grid item>
                        <ListTitle>
                            {`Select contacts for campaign: ${props.campaign.name}`}
                        </ListTitle>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={onSaveClickHandler}
                            sx={{ mt: { xs: 2, md: 0 } }}
                            variant='contained'
                            disabled={saveButtonDisabled}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={onCloseHandler}
                            sx={{ mt: { xs: 2, md: 0 }, ml: 1 }}
                            variant='contained'
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
                <Alerts alerts={alerts} setAlerts={setAlerts} />
                <Card sx={{ height: '80vh', width: '80vw' }}>
                    <List<PersonModel>
                        checkboxSelection
                        columns={columns}
                        rows={people}
                        loading={loading}
                        rowSelectionModel={selection}
                        paginationMode={'client'}
                        onRowSelectionModelChange={(
                            newSelection: GridInputRowSelectionModel
                        ) =>
                            CampaignsController.setSelectionHandler(
                                newSelection,
                                setSelection
                            )
                        }
                        isRowSelectable={() => true}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        rowCount={people.length}
                        pageSizeOptions={[PageSize]}
                    />
                </Card>
            </DialogContent>
        </Dialog>
    );
};

export default SelectPeopleInCampaign;

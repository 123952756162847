import {
    GridInputRowSelectionModel,
    GridRowSelectionModel
} from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { CampaignModel, PersonInCampaignRecord } from '../../models/Campaign';
import { PersonInCampaignEventStatus } from '@advocate-insights/ms-common';
import { SessionType } from '../../contexts/session';
import { CampaignMetricsOnly } from '../../models/CampaignWithMetrics';
import CampaignMetricsAPI, {
    CampaignMetricsAPIGetPaginatedParams
} from '../../api/CampaignMetricsAPI';

export interface PeopleInCampaignObj {
    [key: string]: {
        campaignId: string;
        status: PersonInCampaignEventStatus | string;
        submissionId?: string;
    };
}

export interface CampaignMetricsObj {
    [key: string]: CampaignMetricsOnly;
}

class CampaignsController {
    public static setSelectionHandler = (
        newSelection: GridInputRowSelectionModel,
        setSelection: Dispatch<SetStateAction<GridRowSelectionModel>>
    ): void => {
        if (newSelection instanceof Array) {
            setSelection(newSelection);
            return;
        }

        setSelection([newSelection]);
    };

    public static updateOneCampaign = (
        campaigns: CampaignModel[],
        campaign: CampaignModel
    ): CampaignModel[] => {
        return campaigns.map(
            (camp): CampaignModel => (camp.id === campaign.id ? campaign : camp)
        );
    };

    public static peopleInCampaignArrayToObject = (
        pplArr: PersonInCampaignRecord[]
    ): PeopleInCampaignObj => {
        const pplObj: PeopleInCampaignObj = {};

        pplArr.forEach((ppl) => {
            Object.assign(pplObj, {
                // Returns the same client object without the id property
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                [ppl.personId]: (({ personId, ...noIdCli }) => noIdCli)(ppl)
            });
        });

        return pplObj;
    };

    public static updatePeopleInCampaignObjFromSelection = (
        pplObj: PeopleInCampaignObj,
        selection: GridRowSelectionModel,
        campaignId: string
    ): PeopleInCampaignObj => {
        const newPplObj: PeopleInCampaignObj = {};

        selection.forEach((sel) => {
            if (pplObj[sel]) {
                newPplObj[sel] = pplObj[sel]!;
                return;
            }

            newPplObj[sel] = {
                campaignId,
                status: 'new'
            };
        });

        return newPplObj;
    };

    public static getCampaignMetricsObj = async (
        session: SessionType,
        clientId: string,
        campaigns: CampaignModel[]
    ): Promise<CampaignMetricsObj> => {
        const campaignMetrics: CampaignMetricsObj = {};
        const processNext = true;
        let iterationLimit = 20;
        const pageParams: CampaignMetricsAPIGetPaginatedParams = {
            clientId
        };

        while (
            campaigns.length !== Object.keys(campaignMetrics).length &&
            processNext &&
            iterationLimit
        ) {
            const companyPage = await CampaignMetricsAPI.getMany(
                session,
                pageParams
            );

            if (companyPage.data.length) {
                companyPage.data.forEach((camp) => {
                    if (!campaignMetrics[camp.id]) {
                        campaignMetrics[camp.id] = {
                            peopleScheduled: camp.peopleScheduled,
                            peopleResponded: camp.peopleResponded ?? 0,
                            emailDelivered: camp.emailDelivered ?? 0,
                            trackingLinksMetrics:
                                camp.trackingLinksMetrics ?? []
                        };
                    }
                });
            }

            if (companyPage.count > 0) {
                campaigns = [...campaigns, ...companyPage.data];
            }

            if (!companyPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = companyPage.lastKey;
        }

        return campaignMetrics;
    };
}

export default CampaignsController;

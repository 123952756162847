import { Button } from '@mui/material';
import UploadFileIcon from '../../Icons/UploadFileIcon';
import { useState } from 'react';
import ImportContactsCSVForm, { ImportSource } from './ImportContactsCSVForm';
import { PersonModel } from '../../../models/Person';

interface ImportContactsCSVButtonProps {
    importSource: ImportSource;
    onContactsAddedHandler?:
        | ((
              peopleAdded: PersonModel[],
              peopleDuplicated: PersonModel[]
          ) => void)
        | (() => void);
}

const ImportContactsCSVButton = (
    props: ImportContactsCSVButtonProps
): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);

    const onClickHandler = (): void => {
        setOpen(true);
    };

    const onContactsAddedHandler = (
        peopleAdded: PersonModel[],
        peopleDuplicated: PersonModel[]
    ) => {
        if (props.onContactsAddedHandler) {
            props.onContactsAddedHandler(peopleAdded, peopleDuplicated);
        }
    };

    return (
        <>
            <Button
                onClick={onClickHandler}
                sx={{ mt: { xs: 2, md: 1 }, mb: 1 }}
                variant='contained'
                startIcon={<UploadFileIcon fontSize='small' />}
            >
                Import CSV
            </Button>
            <ImportContactsCSVForm
                open={open}
                setOpen={setOpen}
                importSource={props.importSource}
                onItemAddedHandler={onContactsAddedHandler}
                duplicatePeopleText='duplicate(s) were found. Those contacts were added to this campaign'
            />
        </>
    );
};

export default ImportContactsCSVButton;

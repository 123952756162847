import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import {
    APIGetPaginatedParams,
    ApiError,
    ApiErrorType,
    ApiErrorObject
} from './types';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';
import { CompanyModel, NewCompany } from '../models/Company';

interface CompanyAPIGetPaginatedParams extends APIGetPaginatedParams {
    clientId?: string;
    name?: string;
}
class CompanyAPI {
    private static getCompanyAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_COMPANY_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static create = async (
        session: SessionType,
        companyData: NewCompany
    ): Promise<ApiErrorObject | CompanyModel> => {
        const companyAPI = CompanyAPI.getCompanyAPIAxiosObj(session);

        try {
            const company = await companyAPI.post<CompanyModel>(
                '',
                companyData
            );

            if (company.data) {
                return company.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('creating company');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('creating company');
    };

    public static update = async (
        session: SessionType,
        company: CompanyModel
    ): Promise<ApiErrorObject | CompanyModel> => {
        const companyAPI = CompanyAPI.getCompanyAPIAxiosObj(session);

        try {
            const updatedCompany = await companyAPI.put<CompanyModel>(
                `/${company.id}`,
                company
            );

            if (updatedCompany.data) {
                return updatedCompany.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('updating company');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('updating company');
    };

    public static getMany = async (
        session: SessionType,
        params: CompanyAPIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<CompanyModel>> => {
        const companyAPI = CompanyAPI.getCompanyAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params };

        if (params.lastKey) {
            filter.params.lastKey = JSON.stringify(params.lastKey);
        }

        const { status, data } = await companyAPI.get<
            PaginatedCollection<CompanyModel>
        >('', filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving companies');
        }
    };

    public static getAll = async (
        session: SessionType,
        params: CompanyAPIGetPaginatedParams = {}
    ): Promise<CompanyModel[]> => {
        let companies: CompanyModel[] = [];
        const processNext = true;
        let iterationLimit = 100;
        const pageParams: APIGetPaginatedParams = { ...params };

        while (processNext && iterationLimit) {
            const companyPage = await this.getMany(session, pageParams);

            if (companyPage.count > 0) {
                companies = [...companies, ...companyPage.data];
            }

            if (!companyPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = companyPage.lastKey;
        }

        if (iterationLimit <= 0) {
            throw new ApiError(
                'CompanyAPI.getAll() reached its iteration limit',
                ApiErrorType.IterationLimit
            );
        }

        return companies;
    };

    public static get = async (
        session: SessionType,
        clientId: string,
        companyId: string
    ): Promise<CompanyModel> => {
        const companyAPI = CompanyAPI.getCompanyAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params: { clientId } };

        const { status, data } = await companyAPI.get<CompanyModel>(
            `/${companyId}`,
            filter
        );

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving company');
        }
    };
}

export default CompanyAPI;

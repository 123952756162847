import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { APIGetPaginatedParams, ApiError, ApiErrorType } from './types';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import { SurveyTemplateRecord } from '../models/SurveyTemplate';

export interface SurveyTemnplatesGetPaginatedParams
    extends APIGetPaginatedParams {
    clientId?: string;
}

class SurveyTemplateAPI {
    private static getSurveyTemplateAPIAxiosObj = (
        session?: SessionType,
        path = '/templates'
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL =
            process.env.REACT_APP_SURVEY_TEMPLATE_API_BASEURL! + path;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static getManyTemplates = async (
        session: SessionType,
        params: SurveyTemnplatesGetPaginatedParams = {}
    ): Promise<PaginatedCollection<SurveyTemplateRecord>> => {
        const templateAPI = this.getSurveyTemplateAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params };

        if (params.lastKey) {
            filter.params.lastKey = JSON.stringify(params.lastKey);
        }

        const { status, data } = await templateAPI.get<
            PaginatedCollection<SurveyTemplateRecord>
        >('', filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving survey templates');
        }
    };

    public static getAllTemplates = async (
        session: SessionType,
        clientId: string
    ): Promise<SurveyTemplateRecord[]> => {
        let templates: SurveyTemplateRecord[] = [];
        const processNext = true;
        let iterationLimit = 20;
        const pageParams: SurveyTemnplatesGetPaginatedParams = {
            clientId
        };

        while (processNext && iterationLimit) {
            const companyPage = await this.getManyTemplates(
                session,
                pageParams
            );

            if (companyPage.count > 0) {
                templates = [...templates, ...companyPage.data];
            }

            if (!companyPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = companyPage.lastKey;
        }

        if (iterationLimit <= 0) {
            throw new ApiError(
                'SurveyTemplateAPI.getAllTemplates() reached its iteration limit',
                ApiErrorType.IterationLimit
            );
        }

        return templates;
    };
}

export default SurveyTemplateAPI;

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React from 'react';

interface CommonTabsProps {
    component1?: React.ReactNode;
    component2?: React.ReactNode;
    selectedTab?: number;
}
const CommonTabs = (props: CommonTabsProps) => {
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }
    const [value, setValue] = React.useState(0);
    const a11yProps = (index: number) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`
        };
    };

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label='Contacts' {...a11yProps(0)} />
                    <Tab label='Workflows' {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                {props.component1}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {props.component2}
            </CustomTabPanel>
        </Box>
    );
};

export default CommonTabs;

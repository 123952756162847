import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

interface FactCheckIconProps {
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const FactCheckIcon = (props: FactCheckIconProps): JSX.Element => {
    return <FactCheckOutlinedIcon {...props} />;
};

export default FactCheckIcon;

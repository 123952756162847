import { Box, Grid, MenuItem, Typography } from '@mui/material';
import Overlay from '../../../components/core/Overlay/Overlay';
import { useContext, useEffect, useState } from 'react';
import {
    CampaignStepEventStatus,
    CampaignStepTargetAudience,
    CampaignStepTrigger,
    ObjectType
} from '@advocate-insights/ms-common';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import { Form } from 'react-final-form';
import { validateFormValues } from '../../../utils/forms/validation';
import * as yup from 'yup';
import FormTitle from '../../../components/forms/FormTitle';
import FormRowContainer from '../../../components/forms/FormRowContainer';
import FormButton from '../../../components/forms/FormButton';
import FormTextField from '../../../components/forms/FormTextField';
import {
    CampaignStepRecord,
    NewCampaignStep
} from '../../../models/CampaignStep';
import { StoreContext } from '../../../contexts/store';
import CampaignStepsAPI from '../../../api/CampaignStepsAPI';
import { SessionContext } from '../../../contexts/session';
import { ApiErrorObject } from '../../../api/types';
import CustomDialog from '../../../components/common/CustomDialog/CustomDialog';
import usePermissions from '../../../hooks/usePermissions';
import { permissions } from '../../../utils/AuthHelper/types';
import {
    EmailTemplatesContext,
    EmailTemplatesContextType
} from '../../../contexts/emailTemplates';
import RenderedTemplate from '../../../components/common/RenderedTemplate/RenderedTemplate';
import { EmailTemplateRecord } from '../../../models/EmailTemplate';
import SurveyTemplateAPI from '../../../api/SurveyTemplateAPI';
import { SurveyTemplateRecord } from '../../../models/SurveyTemplate';
import Logger from '../../../utils/logger';
import FormDatePicker from '../../../components/forms/FormDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { TIMEZONES, getCurrentTimezone } from '../../../utils/tz';

dayjs.extend(utc);
dayjs.extend(timezone);

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const validate = validateFormValues(
    yup.object().shape({
        name: yup.string().min(3).required(),
        surveyId: yup.string().min(10).required(),
        emailTemplateId: yup.string().min(10).required(),
        targetAudience: yup
            .string()
            .oneOf(Object.values(CampaignStepTargetAudience))
            .required(),
        trigger: yup
            .string()
            .oneOf(Object.values(CampaignStepTrigger))
            .required(),
        triggerDays: yup.number().min(1).optional()
    })
);

export interface EditCampaignStepInitialValues {
    id: string;
    order: number;
    name: string;
    surveyId: string;
    emailTemplateId: string;
    targetAudience: CampaignStepTargetAudience;
    trigger: CampaignStepTrigger;
    triggerDays?: number;
    triggerSchedule?: string;
    triggerTimezone?: string;
    status: CampaignStepEventStatus | string;
}

interface CampaignStepFormProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    campaignId: string;
    campaignSteps: CampaignStepRecord[];
    setCampaignSteps: (steps: CampaignStepRecord[]) => void;
    title?: string;
    submitButtonText?: string;
    initialValues?: EditCampaignStepInitialValues;
}

const CampaignStepForm = (props: CampaignStepFormProps): JSX.Element => {
    usePermissions(permissions.campaignsWrite);

    const [processing, setProcessing] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const { store } = useContext(StoreContext);
    const { session } = useContext(SessionContext);
    const { emailTemplates } = useContext<EmailTemplatesContextType>(
        EmailTemplatesContext
    );
    const [emailTemp, setEmailTemp] = useState<
        EmailTemplateRecord | undefined
    >();
    const [surveys, setSurveys] = useState<SurveyTemplateRecord[]>([]);

    // Get survey templates
    useEffect(() => {
        if (session.idToken && store.currentClientId) {
            const getSurveys = async () => {
                try {
                    const surveys = await SurveyTemplateAPI.getAllTemplates(
                        session,
                        store.currentClientId!
                    );
                    setSurveys(surveys);
                } catch (err: unknown) {
                    const errNo = await Logger.error(JSON.stringify(err));
                    setAlerts([
                        ...alerts,
                        {
                            severity: 'error',
                            text: `[${errNo}] Could not get surveys`
                        }
                    ]);
                }
            };

            getSurveys();
        }
    }, [session.idToken, store.currentClientId]);

    const showComponentOnSelectedTrigger = (values: ObjectType) => {
        if (values.trigger === CampaignStepTrigger.NoResponse) {
            return (
                <FormRowContainer>
                    <FormTextField
                        name='triggerDays'
                        label='Send email after # days'
                        type='number'
                        fullWidth
                    />
                </FormRowContainer>
            );
        } else if (values.trigger === CampaignStepTrigger.CampaignScheduled) {
            const currentTimezone = getCurrentTimezone();
            const timezones = TIMEZONES.filter(
                (item) => item.ianaId !== currentTimezone!.ianaId
            );
            return (
                <FormRowContainer>
                    <FormDatePicker
                        name='triggerSchedule'
                        label='Schedule campaing execution'
                        views={['year', 'day', 'month', 'hours']}
                        minTime={dayjs().add(1, 'hour')}
                    />
                    <FormTextField
                        name='triggerTimezone'
                        label='Timezone'
                        select
                        sx={{ ml: 1, minWidth: 120 }}
                    >
                        <MenuItem
                            key={currentTimezone!.tzId}
                            value={currentTimezone!.ianaId}
                        >
                            {currentTimezone!.tzId}
                        </MenuItem>
                        {timezones.map((item) => (
                            <MenuItem key={item.tzId} value={item.ianaId}>
                                {item.tzId}
                            </MenuItem>
                        ))}
                    </FormTextField>
                </FormRowContainer>
            );
        }
        return;
    };

    const onSubmitHandler = async (
        values: ObjectType
    ): Promise<ObjectType | void> => {
        setProcessing(true);

        const campaignStepData: NewCampaignStep = {
            order: props.initialValues?.order ?? props.campaignSteps.length + 1,
            name: String(values.name),
            clientId: session.user?.clientId || String(store.currentClientId),
            campaignId: props.campaignId,
            surveyId: String(values.surveyId),
            emailTemplateId: String(values.emailTemplateId),
            targetAudience: String(
                values.targetAudience
            ) as CampaignStepTargetAudience,
            trigger: String(values.trigger) as CampaignStepTrigger
        };

        if (values.triggerDays) {
            campaignStepData.triggerDays = Number(values.triggerDays);
        }

        if (
            values.trigger === CampaignStepTrigger.CampaignScheduled &&
            values.triggerSchedule &&
            values.triggerTimezone
        ) {
            const date = dayjs(values.triggerSchedule?.toLocaleString());
            campaignStepData.triggerProperties = {
                triggerDateTime: date.format(DATE_FORMAT),
                timezone: String(values.triggerTimezone)
            };
        }

        let campaignStep: CampaignStepRecord | ApiErrorObject;

        if (props.initialValues && props.initialValues.id) {
            // Edit
            campaignStep = await CampaignStepsAPI.update(session, {
                ...campaignStepData,
                id: props.initialValues.id,
                status: props.initialValues.status
            });
        } else {
            // Create
            campaignStep = await CampaignStepsAPI.create(
                session,
                campaignStepData
            );
        }
        setProcessing(false);

        if (campaignStep && campaignStep.id) {
            if (props.campaignSteps && props.setCampaignSteps) {
                props.setCampaignSteps([
                    // this trick avoid a duplicate of an editted campaign step
                    ...props.campaignSteps.filter(
                        (step) =>
                            !props.initialValues?.id ||
                            step.id !== props.initialValues?.id
                    ),
                    campaignStep as CampaignStepRecord
                ]);
            }

            props.setOpen(false);
            return;
        }

        return campaignStep;
    };

    const onCloseHandler = () => {
        setEmailTemp(undefined);
        props.setOpen(false);
    };

    const onEmailTemplateChangeHandler = (event: unknown): void => {
        if (
            typeof event === 'object' &&
            event &&
            'target' in event &&
            typeof event.target === 'object' &&
            event.target &&
            'value' in event.target &&
            event.target.value
        ) {
            const selectedValue = event.target.value;
            const template = emailTemplates.find(
                (item) => item.id === selectedValue
            );

            if (template) {
                setEmailTemp(template);
            }
        }
    };

    const findTemplate = (templateId: string | undefined) => {
        if (!templateId) {
            return '';
        }
        const template = emailTemplates.find(
            (item) => item.id === templateId
        );

        if (template) {
            return template.htmlContent;
        }
        return '';
    }

    return (
        <CustomDialog
            open={props.open}
            onClose={onCloseHandler}
            PaperProps={{
                sx: {
                    maxWidth: 'none',
                    maxHeight: 'none'
                }
            }}
        >
            <Overlay processing={processing} />

            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Form
                initialValues={
                    props.initialValues || {
                        surveyId: surveys[0]?.id,
                        targetAudience: CampaignStepTargetAudience.Campaign,
                        trigger: CampaignStepTrigger.CampaignStarted,
                        triggerTimezone: getCurrentTimezone()?.ianaId
                    }
                }
                onSubmit={onSubmitHandler}
                validate={validate}
                render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    invalid,
                    values
                }) => (
                    <Grid container>
                        <Grid item xs='auto' sx={{ minWidth: 400 }}>
                            <form
                                {...form}
                                id='join-now-form'
                                onSubmit={handleSubmit}
                            >
                                <FormTitle
                                    text={props.title || 'Add Campaign Step'}
                                />
                                <FormRowContainer>
                                    <FormTextField
                                        name='name'
                                        label='Name'
                                        fullWidth
                                        required
                                        autoFocus
                                    />
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='surveyId'
                                        label='Survey'
                                        select
                                        fullWidth
                                    >
                                        {surveys.map((survey) => (
                                            <MenuItem
                                                key={survey.id}
                                                value={survey.id}
                                            >
                                                {survey.name}
                                            </MenuItem>
                                        ))}
                                    </FormTextField>
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='emailTemplateId'
                                        label='Email Template'
                                        select
                                        fullWidth
                                        onChange={onEmailTemplateChangeHandler}
                                    >
                                        {emailTemplates.map((email) => (
                                            <MenuItem
                                                key={email.id}
                                                value={email.id}
                                            >
                                                {email.name}
                                            </MenuItem>
                                        ))}
                                    </FormTextField>
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='targetAudience'
                                        label='Audience'
                                        select
                                        fullWidth
                                    >
                                        <MenuItem
                                            key={
                                                CampaignStepTargetAudience.Campaign
                                            }
                                            value={
                                                CampaignStepTargetAudience.Campaign
                                            }
                                        >
                                            Campaign All
                                        </MenuItem>
                                        <MenuItem
                                            key={
                                                CampaignStepTargetAudience.NoResponse
                                            }
                                            value={
                                                CampaignStepTargetAudience.NoResponse
                                            }
                                        >
                                            No Response
                                        </MenuItem>
                                    </FormTextField>
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='trigger'
                                        label='Trigger'
                                        select
                                        fullWidth
                                    >
                                        <MenuItem
                                            key={
                                                CampaignStepTrigger.CampaignStarted
                                            }
                                            value={
                                                CampaignStepTrigger.CampaignStarted
                                            }
                                        >
                                            Campaign Started
                                        </MenuItem>
                                        <MenuItem
                                            key={
                                                CampaignStepTrigger.CampaignScheduled
                                            }
                                            value={
                                                CampaignStepTrigger.CampaignScheduled
                                            }
                                        >
                                            Campaign Scheduled
                                        </MenuItem>
                                        <MenuItem
                                            key={CampaignStepTrigger.NoResponse}
                                            value={
                                                CampaignStepTrigger.NoResponse
                                            }
                                        >
                                            No Response after # days
                                        </MenuItem>
                                    </FormTextField>
                                </FormRowContainer>
                                {showComponentOnSelectedTrigger(values)}
                                <FormRowContainer>
                                    <FormButton
                                        disabled={
                                            submitting || pristine || invalid
                                        }
                                        type='submit'
                                    >
                                        {props.submitButtonText || 'Add'}
                                    </FormButton>
                                </FormRowContainer>
                            </form>
                        </Grid>
                        <Grid item xs='auto'>
                            <Typography
                                sx={{ mt: 2.8, ml: 3.6, fontWeight: 'bold' }}
                            >
                                Email Template
                            </Typography>
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    width: 420,
                                    px: 2,
                                    py: 1,
                                    border: 1,
                                    ml: 2.6,
                                    borderRadius: 1,
                                    borderColor: 'lightgray',
                                    minHeight: 360
                                }}
                            >
                                <RenderedTemplate
                                    template={String(
                                        emailTemp?.htmlContent ?? findTemplate(props.initialValues?.emailTemplateId)
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            />
        </CustomDialog>
    );
};

export default CampaignStepForm;

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider
} from '@mui/material';
import { PropsWithChildren } from 'react';

interface ConfirmDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose?: () => void;
    title?: string;
}

const CloseDialog = (
    props: PropsWithChildren<ConfirmDialogProps>
): JSX.Element => {
    const onCloseHandler = () => {
        props.setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <Dialog open={props.open} onClose={onCloseHandler}>
            {Boolean(props.title) && (
                <>
                    <DialogTitle>{props.title}</DialogTitle>
                    <Divider />
                </>
            )}
            <DialogContent sx={{ minWidth: 400, minHeight: 100 }}>
                {props.children}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={onCloseHandler}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CloseDialog;

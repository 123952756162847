import { PaginatedCollection } from '@advocate-insights/ms-common';
import IntegrationAPI, {
    ApiCompanyRecord,
    ApiContactRecord
} from '../../../api/IntegrationAPI';
import { ApiErrorObject } from '../../../api/types';
import { SessionType } from '../../../contexts/session';
import { ConnectionRecord } from '../../../models/Connection';
import { ContactModel, NewContact } from '../../../models/Person';
import PersonAPI from '../../../api/PersonAPI';
import { NewCompany } from '../../../models/Company';
import CompanyAPI from '../../../api/CompanyAPI';

const MAX_IMPORT_COMPANIES = 10000;
const CRM_IMPORT_SOURCE = 'crm';

interface ApiComaniesObj {
    [key: string]: {
        companyName: string;
        companyId?: string;
    };
}

interface ImportResponse {
    imported: ContactModel[];
    duplicates: ContactModel[];
    skipped: number;
}

interface ImportContactResponse {
    result: 'imported' | 'duplicate' | 'skipped';
    contact?: ContactModel;
}

class ImportContactsCRMHelper {
    public static hasCallableConnections = (
        connections?: ConnectionRecord[]
    ): boolean => {
        if (connections !== undefined) {
            for (const conn of connections) {
                if (this.connectionIsCollable(conn)) {
                    return true;
                }
            }
        }

        return false;
    };

    public static connectionIsCollable = (conn: ConnectionRecord): boolean => {
        return (
            conn.integration_state === 'configured' &&
            conn.enabled &&
            conn.state === 'callable'
        );
    };

    public static import = async (
        session: SessionType,
        connection: ConnectionRecord,
        clientId: string
    ): Promise<ImportResponse> => {
        const response: ImportResponse = {
            imported: [],
            duplicates: [],
            skipped: 0
        };
        const companiesObj = await this.getApiCompaniesObj(
            session,
            connection,
            clientId
        );

        let apiContacts: ApiErrorObject | PaginatedCollection<ApiContactRecord>;
        let next: string | undefined = undefined;

        do {
            // Get contacts
            apiContacts = await IntegrationAPI.getConnectionContacts(
                session,
                clientId,
                connection,
                next
            );

            if (!(apiContacts.data instanceof Array)) {
                throw new Error('Error importing contacts from CRM');
            }

            await Promise.all(
                apiContacts.data.map(async (contact) => {
                    const importResult = await this.importContact(
                        session,
                        clientId,
                        contact,
                        companiesObj,
                        connection
                    );

                    switch (importResult.result) {
                        case 'imported':
                            response.imported.push(
                                importResult.contact as ContactModel
                            );
                            break;
                        case 'duplicate':
                            response.duplicates.push(
                                importResult.contact as ContactModel
                            );
                            break;
                        default:
                            response.skipped++;
                    }
                })
            );

            if (
                apiContacts.lastKey &&
                typeof apiContacts.lastKey === 'object' &&
                apiContacts.lastKey.next
            ) {
                next = String(apiContacts.lastKey.next);
            }
        } while (
            apiContacts.lastKey &&
            typeof apiContacts.lastKey === 'object' &&
            apiContacts.lastKey.next
        );

        return response;
    };

    private static importContact = async (
        session: SessionType,
        clientId: string,
        contact: ApiContactRecord,
        companiesObj: ApiComaniesObj,
        connection: ConnectionRecord
    ): Promise<ImportContactResponse> => {
        if (this.importableContact(contact)) {
            const newContact: NewContact = {
                email: String(contact.emails![0]!.email),
                name: String(contact.first_name),
                clientId,
                source: CRM_IMPORT_SOURCE,
                sourceMetadata: {
                    connectionId: connection.id,
                    serviceId: connection.service_id,
                    unifiedApi: connection.unified_api
                }
            };

            if (contact.last_name) {
                newContact.lastName = contact.last_name;
            }

            if (contact.company_id && companiesObj[contact.company_id]) {
                newContact.companyId = await this.getCompany(
                    contact.company_id,
                    session,
                    clientId,
                    connection,
                    companiesObj
                );
            }

            const response = await PersonAPI.create(session, newContact, true);

            if (!response.id) {
                if (response.email) {
                    const existingContact = await PersonAPI.getMany(session, {
                        email: newContact.email,
                        clientId
                    });

                    if (existingContact.count && existingContact.data[0]) {
                        return {
                            result: 'duplicate',
                            contact: existingContact.data[0]
                        };
                    }
                }

                throw new Error('Error importing contacts from CRM');
            }

            return {
                result: 'imported',
                contact: response as ContactModel
            };
        }

        return {
            result: 'skipped'
        };
    };

    private static getCompany = async (
        apiCompanyId: string,
        session: SessionType,
        clientId: string,
        connection: ConnectionRecord,
        companiesObj: ApiComaniesObj
    ): Promise<string> => {
        if (
            companiesObj[apiCompanyId] &&
            companiesObj[apiCompanyId]?.companyId
        ) {
            return String(companiesObj[apiCompanyId]?.companyId);
        }

        return this.importCompany(
            session,
            clientId,
            String(companiesObj[apiCompanyId]?.companyName),
            connection
        );
    };

    private static importCompany = async (
        session: SessionType,
        clientId: string,
        companyName: string,
        connection: ConnectionRecord
    ): Promise<string> => {
        const newCompany: NewCompany = {
            name: companyName,
            clientId,
            source: CRM_IMPORT_SOURCE,
            sourceMetadata: {
                connectionId: connection.id,
                serviceId: connection.service_id,
                unifiedApi: connection.unified_api
            }
        };

        const response = await CompanyAPI.create(session, newCompany);

        if (!response.id) {
            if (response.name) {
                const companies = await CompanyAPI.getMany(session, {
                    name: companyName,
                    clientId
                });

                if (companies.count && companies.data[0]) {
                    return companies.data[0].name;
                }
            }

            throw new Error('Error importing contacts from CRM');
        }

        return response.id;
    };

    private static importableContact = (contact: ApiContactRecord): boolean => {
        return Boolean(
            contact.emails &&
                contact.emails[0] &&
                contact.emails[0].email &&
                contact.first_name
        );
    };

    private static getApiCompaniesObj = async (
        session: SessionType,
        connection: ConnectionRecord,
        clientId: string
    ): Promise<ApiComaniesObj> => {
        let apiCompanies:
            | ApiErrorObject
            | PaginatedCollection<ApiCompanyRecord>;
        const apiCompaniesObj: ApiComaniesObj = {};
        let next: string | undefined = undefined;

        do {
            // Get companies
            apiCompanies = await IntegrationAPI.getConnectionCompanies(
                session,
                clientId,
                connection,
                next
            );

            if (!(apiCompanies.data instanceof Array)) {
                throw new Error('Error importing contacts from CRM');
            }

            // Process company array into object for easy find
            apiCompanies.data.forEach((company) => {
                if (company.id && company.name) {
                    apiCompaniesObj[company.id] = {
                        companyName: company.name
                    };
                }
            });

            if (
                apiCompanies.lastKey &&
                typeof apiCompanies.lastKey === 'object' &&
                apiCompanies.lastKey.next
            ) {
                next = String(apiCompanies.lastKey.next);
            }
        } while (
            apiCompanies.lastKey &&
            typeof apiCompanies.lastKey === 'object' &&
            apiCompanies.lastKey.next &&
            Object.keys(apiCompaniesObj).length <= MAX_IMPORT_COMPANIES
        );

        return apiCompaniesObj;
    };
}

export default ImportContactsCRMHelper;
